import engage from '@/api/engage'
import insurance from '@/api/insurance'
import { has, isEmpty } from 'lodash'
import {
  normalizeAllowedDeviceClasses,
  normalizeComponentData,
  normalizeCustomerData,
  normalizeDeviceClasses,
  normalizeProductQuote,
  prepareEngageDirectCheckoutData,
  prepareEngagePrefillCheckoutData,
  prepareInsuranceCheckoutData
} from '@/store/helpers'

const handleAsyncCall = async (commit, asyncCall, callback, error) => {
  if (asyncCall?.status === 200) {
    callback(commit, asyncCall.data.data)
  } else {
    commit('setStatus', {
      message: `Fehler beim Laden der Daten.`, icon: 'alert-circle-outline'
    })
    if (error) {
      error(commit, asyncCall)
    }
  }
}

const actions = {
  async addCartPremium({ commit, state }, payload) {
    const cart_product_id = state.cart.products[payload.cart_index].id
    const addPremium = await insurance.addCartPremium(cart_product_id, payload.premium_product_id)
    await handleAsyncCall(commit, addPremium, (commit, data) => { commit('setCart', data) })

    if (addPremium?.status === 200) {
      commit('setCart', addPremium.data.data)
      commit('setStatus', { message: 'Premium-Option erfolgreich hinzugefügt.', icon: 'information' })
    } else {
      commit('setStatus', {
        message: `Premium-Option konnte nicht hinzugefügt werden: [ ${ addPremium } ]`,
        icon: 'alert-circle-outline'
      })
    }
  },

  async addCartCoupon({ commit, state }) {
    const cart_product_id = state.cart.products[0].id
    const coupon_code = state.current_akp.voucher
    const broker_number = state.current_akp.number
    const addCoupon = await insurance.addCartCoupon(cart_product_id, coupon_code, broker_number)

    if (addCoupon?.status === 200) {
      commit('setCart', addCoupon.data.data)
      commit('setHasVoucher', true)
      commit('setStatus', { message: 'Beitragsfreier Monat erfolgreich hinzugefügt.', icon: 'information' })
    } else {
      commit('setStatus', {
        message: `${ addCoupon } (verwendeter Code: "${ coupon_code }")`, icon: 'alert-circle-outline'
      })
    }
  },

  async addCartComponent({ commit }, payload) {
    const component_data = {
      condition: payload.condition,
      manufacturer: payload.manufacturer,
      model_name: payload.model_name,
      purchase_date: payload.purchase_date,
      purchase_price: payload.purchase_price
    }

    if (payload.serial_number !== null) {
      component_data.serial_number = payload.serial_number
    }

    const addCartComponent = await insurance.addCartComponent(payload.cart_device_id, payload.component_class_id, component_data)

    if (addCartComponent?.status === 200) {
      await commit('setCart', addCartComponent.data.data)
      payload.is_in_cart = true
      payload.cart_component_id = addCartComponent.data.data.products[payload.cart_index].device.components[payload.component_index].id
      await commit('addSelectedComponent', payload)
      commit('setStatus', { message: 'Komponente erfolgreich hinzugefügt.', icon: 'information' })
    } else {
      commit('setStatus', {
        message: `Komponente konnte nicht hinzugefügt werden: [ ${ addCartComponent } ]`,
        icon: 'alert-circle-outline'
      })
    }
  },

  async addCartProduct({ commit, dispatch, state }) {
    if (!isEmpty(state.product_quote[0])) {
      const product_variant_id = state.product_quote[0]?.id
      const device_class_id = state.current_device_class_id

      if (state.selected_product.value === 'komplettschutz_3_für_2' && isEmpty(state.cart.promotions)) {
        const promotion_id = state.selected_product.promotion_id
        await insurance.addCartPromotion(promotion_id)
      }

      const cartProduct = await insurance.addCartProduct(product_variant_id, device_class_id)

      if (cartProduct?.status === 200 || cartProduct?.status === 201) {
        await commit('setCart', cartProduct.data.data)
      } else {
        commit('setStatus', {
          message: `Warenkorb konnte nicht geladen werden: [ ${ cartProduct } ]`,
          icon: 'alert-circle-outline'
        })
      }
    }

  },

  async addCartProductDiscount({ commit, state }, payload) {
    const discount_id = payload.discount_id
    const cart_product_id = state.cart.products[payload.cart_index].id
    const addProductDiscount = await insurance.addCartProductDiscount(discount_id, cart_product_id)

    if (addProductDiscount?.status === 200) {
      commit('setCart', addProductDiscount.data.data)
      commit('setStatus', { message: 'Rabattierte Vorauszahlung erfolgreich hinzugefügt.', icon: 'information' })
    } else {
      commit('setStatus', {
        message: `Rabattierte Vorauszahlung konnte nicht hinzugefügt werden: [ ${ addProductDiscount } ]`,
        icon: 'alert-circle-outline'
      })
    }
  },

  async addNewContractDevice({ dispatch }, index) {
    await dispatch('getProductQuote', index)
    await dispatch('addCartProduct')
    dispatch('getAllowedDeviceClasses')
  },

  async auth({ commit }) {
    const auth = await insurance.auth()
    if (auth?.status === 200) {
      commit('setInsuranceToken', auth.data.access_token)
    }
  },

  async checkoutEngage({ state, commit }, type) {
    const checkout_data = (type === 'prefill') ? prepareEngagePrefillCheckoutData(state) : prepareEngageDirectCheckoutData(state)

    if (type === 'prefill') {
      const saveCart = await insurance.saveCart()
      await commit('setCart', saveCart.data.data)
    }

    const checkoutEngage = await engage.checkout(checkout_data)

    if (checkoutEngage?.status === 200) {
      const message = (type === 'prefill')
        ? 'E-Mail erfolgreich versendet.'
        : `${ state.insurance_contract[0].message } (Vertragsnr.: ${ state.insurance_contract[0].contract_number })`
      commit('setStatus', { message, icon: 'check' })
      commit('setCheckoutDone', true)
      return true
    } else {
      const message = (type === 'prefill')
        ? 'E-Mail konnte nicht versendet werden.'
        : `Checkout nicht erfolgreich: [ '${ checkoutEngage }' ]`
      commit('setStatus', { message, icon: 'alert-circle-outline' })
      return false
    }
  },

  async checkoutInsurance({ state, commit }) {
    const checkout_data = prepareInsuranceCheckoutData(state)
    const checkoutInsurance = await insurance.checkoutCart(checkout_data)

    if (checkoutInsurance?.status === 200) {
      commit('setInsuranceContract', checkoutInsurance.data.data)
      return true
    } else {
      commit('setStatus', {
        message: `Checkout nicht erfolgreich: [ ${ checkoutInsurance } ]`,
        icon: 'alert-circle-outline'
      })
      return false
    }
  },

  async deleteCartComponent({ commit }, payload) {
    const deleteCartComponent = await insurance.deleteCartComponent(payload.cart_component_id)

    if (deleteCartComponent?.status === 200) {
      await commit('setCart', deleteCartComponent.data.data)
      await commit('deleteSelectedComponent', {
        cart_component_id: payload.cart_component_id,
        cart_index: payload.cart_index
      })
      commit('setStatus', { message: 'Komponente erfolgreich entfernt.', icon: 'information' })
    } else {
      commit('setStatus', {
        message: `Komponente konnte nicht entfernt werden: [ ${ deleteCartComponent } ]`,
        icon: 'alert-circle-outline'
      })
    }
  },

  async deleteCartPremium({ commit, state }, payload) {
    const currentProduct = state.cart.products[payload.cart_index]
    const cart_product_premium_id = currentProduct.premiums.find(premium => premium.original_premium_id === payload.premium_product_id).id
    const deletePremium = await insurance.deleteCartPremium(cart_product_premium_id)

    if (deletePremium?.status === 200) {
      commit('setCart', deletePremium.data.data)
      commit('setStatus', { message: 'Premium-Option erfolgreich entfernt.', icon: 'information' })
    } else {
      commit('setStatus', {
        message: `Premium-Option konnte nicht entfernt werden: [ ${ deletePremium } ]`,
        icon: 'alert-circle-outline'
      })
    }
  },

  async deleteCartCoupon({ commit, state }) {
    const cart_product_id = state.cart.products[0].id
    const deleteCoupon = await insurance.deleteCartCoupon(cart_product_id)

    if (deleteCoupon?.status === 200) {
      commit('setCart', deleteCoupon.data.data)
      commit('setHasVoucher', false)
      commit('setStatus', { message: 'Beitragsfreier Monat erfolgreich entfernt.', icon: 'information' })
    } else {
      commit('setStatus', {
        message: `Beitragsfreier Monat konnte nicht entfernt werden: [ ${ deleteCoupon } ]`,
        icon: 'alert-circle-outline'
      })
    }
  },

  async deleteCartProduct({ commit, dispatch, state }, cart_index) {
    commit('loadingStart')

    const cart_product_id = state.cart.products[cart_index].id
    const deleteProduct = await insurance.deleteCartProduct(cart_product_id)

    if (deleteProduct?.status === 200) {
      commit('setCart', deleteProduct.data.data)
      commit('removeSelectedDevice', cart_index)

      if (state.selected_devices.length === 0) {
        dispatch('pruneCart')
        commit('resetDevice')
      } else {
        dispatch('getAllowedDeviceClasses')
      }
      commit('setStatus', { message: 'Produkt erfolgreich entfernt.', icon: 'information' })
    } else {
      commit('setStatus', {
        message: `Produkt konnte nicht entfernt werden: [ ${ deleteProduct } ]`,
        icon: 'alert-circle-outline'
      })
    }
    commit('loadingStop')
  },

  async deleteCartProductDiscount({ commit, state }, payload) {
    const currentProduct = state.cart.products[payload.cart_index]
    const cart_product_discount_id = currentProduct.discounts[0].id
    const deleteProductDiscount = await insurance.deleteCartProductDiscount(cart_product_discount_id)

    if (deleteProductDiscount?.status === 200) {
      commit('setCart', deleteProductDiscount.data.data)
      commit('setStatus', { message: 'Rabattierte Vorauszahlung erfolgreich entfernt.', icon: 'information' })
    } else {
      commit('setStatus', {
        message: `Rabattierte Vorauszahlung konnte nicht entfernt werden: [ ${ deleteProductDiscount } ]`,
        icon: 'alert-circle-outline'
      })
    }
  },

  async getAllowedDeviceClasses({ commit, state, getters }) {
    if (!isEmpty(state.cart) && getters.cartHasActive3for2Promotion) {
      const cart_promotion_id = state.cart.promotions[0].id
      const allowedDeviceClasses = await insurance.getAllowedDeviceClasses(cart_promotion_id)

      if (allowedDeviceClasses?.status === 200) {
        commit('setAllowedDeviceClasses', normalizeAllowedDeviceClasses(allowedDeviceClasses.data.data))
        commit('filterDevicesByDeviceClass')
      } else {
        commit('setStatus', {
          message: `Fehler beim Laden der erlaubten Geräteklassen: [ ${ allowedDeviceClasses } ]`,
          icon: 'alert-circle-outline'
        })
      }
    }
  },

  async getComponentClasses({ commit, dispatch, getters, state }) {
    if (!getters.hasInsuranceToken) {
      await dispatch('auth')
    }
    const response = await insurance.getComponentClasses()

    if (response?.status === 200) {
      commit('setDeviceClasses', normalizeComponentData(response.data.data, state.device_classes))
    } else {
      commit('setStatus', {
        message: `Fehler beim Laden der Komponentenklassen: [ ${ response } ]`,
        icon: 'alert-circle-outline'
      })
    }
  },

  async getCustomerData({ commit }, { data, by }) {
    const response = (by === 'customer_number')
      ? await engage.getCustomer(data)
      : await engage.getCustomerByContract(data)

    if (response?.status === 200) {
      const customerData = normalizeCustomerData(response.data.data)
      commit('setCustomerData', customerData)
      commit('setHasValidCustomerData', true)
      commit('setActiveDeviceId', customerData.devices[0].id)
      commit('setStatus', { message: 'Kundendaten erfolgreich geladen.', icon: 'information' })
    } else {
      commit('setStatus', { message: response, icon: 'alert-circle-outline' })
    }

  },

  async getEmailTemplates({ commit, state }) {
    const filter = (state.is_new_contract) ? 'new' : 'stock'
    const response = await engage.getEmailTemplates(filter)

    if (response?.status === 200) {
      commit('setEmailTemplates', response.data.data)
      commit('setCommunicationSettings', { direct_contract: false, prefill_mode: 'engage' })
      commit('showMailCommunication', true)
    } else {
      commit('setStatus', { message: response, icon: 'alert-circle-outline' })
    }
  },

  async getManufacturers({ commit }, { winsure_id, index }) {
    const response = await engage.getManufacturers(winsure_id)
    let manufacturers = response.data.data

    if (!manufacturers.find(el => el.id === 99)) {
      manufacturers.unshift({ id: 99, name: 'Sonstige' })
    }

    if (response?.status === 200) {
      const payload = { index, manufacturers }
      commit('setManufacturers', payload)
    } else {
      commit('setStatus', { message: response, icon: 'alert-circle-outline' })
    }
  },

  async getProductOffers({ commit, dispatch, getters }, device_class_id) {
    if (!getters.hasInsuranceToken) {
      await dispatch('auth')
    }

    const response = await insurance.getProductOffers(device_class_id)
    if (response?.status === 200) {
      commit('setProductOffers', response.data.data)
      commit('setStatus', { message: 'Tarife erfolgreich geladen.', icon: 'information' })
    } else {
      commit('unselectProduct')
      commit('setStatus', {
        message: `Produktangebote für "id: ${ device_class_id }" konnten nicht geladen werden: [ ${ response } ]`,
        icon: 'alert-circle-outline'
      })
    }
  },

  async getPromotionOffers({ commit }, device_class_id) {
    const response = await insurance.getPromotionOffers(device_class_id)
    if (response?.status === 200) {
      commit('setPromotionOffers', response.data.data)
    } else {
      commit('setStatus', {
        message: `Promotionangebote für "id: ${ device_class_id }" konnten nicht geladen werden. [${ response }]`,
        icon: 'alert-circle-outline'
      })
    }
  },

  async getProductQuote({ commit, state, getters }, index) {
    //const isNewDevice = (has(state.selected_devices[index], 'is_new_device') && state.selected_devices[index].is_new_device)
    const currentDevice = (isEmpty(state.selected_devices[index]))
      ? state.devices.find(device => device.id === state.current_device_id)
      : state.selected_devices[index]
    const product_id = getters.cartHasActive3for2Promotion ? state.product_offers[0].id : state.selected_product.product_id
    const isExistingDevice = (state.selected_devices[index] !== undefined)
    const quoteData = {
      product_id,
      device_class_id: state.current_device_class_id,
      device_purchase_date: currentDevice.purchased_at.split(' ')[0],
      device_price: currentDevice.purchase_price.replace(',', '.'),
      device_condition: currentDevice.device_condition
    }

    const quote = await insurance.getProductQuote(quoteData)
    if (quote?.status === 200) {
      commit('setProductQuote', normalizeProductQuote(quote.data.data))

      if (!isExistingDevice) {
        commit('addSelectedDevice', currentDevice)
      } else {
        commit('setPremiums', index)
      }
    } else {
      commit('setStatus', {
        message: `Product-Quote konnte nicht geladen werden: [ ${ quote } ]`,
        icon: 'alert-circle-outline'
      })
      commit('loadingStop')
    }

  },

  async login({ commit, state }, { username, password }) {
    const response = await engage.login(username, password)
    const loginData = response.data

    if (response?.status === 200) {
      commit('setAccessToken', loginData.access_token)
      commit('setUserData', loginData.data)
      commit('setDeviceClasses', normalizeDeviceClasses(loginData.device_classes))
      commit('setEmployees', loginData.employees)

      const currentEmployee = state.employees.find(employee => employee.id === loginData.data.id)
      commit('setCurrentEmployee', currentEmployee)
      commit('setCurrentAkp', currentEmployee.akp_numbers[0])
      commit('setStatus', { message: 'Login erfolgreich' })
    } else {
      commit('setStatus', { message: 'Login fehlgeschlagen', icon: 'alert-circle-outline' })
    }
  },

  logoutUser({ commit }) {
    commit('logout')
    commit('setStatus', { message: 'Logout erfolgreich' })
  },

  async pruneCart({ dispatch, getters }) {
    if (!getters.hasInsuranceToken) {
      await dispatch('auth')
    }
    return await insurance.pruneCart()
  },

  async selectDevice({ commit, dispatch, getters }, deviceData) {
    commit('deviceLoadingStart', deviceData.device_id)
    commit('setCurrentDeviceId', deviceData.device_id)
    commit('setCurrentDeviceClassId', deviceData.device_class_id)
    commit('showMailCommunication', false)

    if (!getters.hasInsuranceToken) {
      await dispatch('auth')
    }

    if (!getters.cartHasActive3for2Promotion) {
      commit('unselectProduct')
      dispatch('pruneCart')
    }

    await dispatch('getProductOffers', deviceData.device_class_id)
    dispatch('getPromotionOffers', deviceData.device_class_id)

    if (getters.cartHasActive3for2Promotion) {
      await dispatch('getProductQuote')
      await dispatch('getAllowedDeviceClasses')
      await dispatch('addCartProduct')
    }

    commit('deviceLoadingStop', deviceData.device_id)
  },

  async selectNewContractDevice({ commit, dispatch, getters }, deviceData) {
    commit('setCurrentDeviceId', deviceData.device_id)
    commit('setCurrentDeviceClassId', deviceData.device_class_id)
    commit('updateNewContractDevice')

    if (!getters.hasInsuranceToken) {
      await dispatch('auth')

    }
    await dispatch('getProductOffers', deviceData.device_class_id)
    dispatch('getPromotionOffers', deviceData.device_class_id)
  },

  async selectProduct({ commit, dispatch, state, getters }, product) {
    commit('loadingStart')
    commit('setSelectedProduct', product)

    if (!state.is_new_contract && !state.is_first_device) {
      await dispatch('getProductQuote')
      await dispatch('addCartProduct')
    }

    if (getters.cartHasActive3for2Promotion) {
      dispatch('getAllowedDeviceClasses')
    }
    commit('loadingStop')
  },

  async updateCartComponent({ commit }, payload) {
    const component_data = {
      condition: payload.condition,
      manufacturer: payload.manufacturer,
      model_name: payload.model_name,
      purchase_date: payload.purchase_date,
      purchase_price: payload.purchase_price,
      serial_number: payload.serial_number
    }

    const updateCartComponent = await insurance.updateCartComponent(payload.cart_component_id, component_data)
    if (updateCartComponent?.status === 200) {
      await commit('setCart', updateCartComponent.data.data)
      await commit('updateSelectedComponent', payload)
      commit('setStatus', { message: 'Komponente erfolgreich aktualisiert.', icon: 'information' })
    } else {
      commit('setStatus', {
        message: `Komponente konnte nicht aktualisiert werden: [ ${ updateCartComponent } ]`,
        icon: 'alert-circle-outline'
      })
    }
  },

  async updateCartProduct({ commit, dispatch, state }, cart_index) {
    const cart_product_id = state.cart.products[cart_index].id
    commit('loadingStart')
    const deleteProduct = await insurance.deleteCartProduct(cart_product_id)

    if (deleteProduct?.status === 200) {
      await dispatch('getProductQuote', cart_index)
      await dispatch('addCartProduct')
      await commit('setStatus', { message: 'Gerät erfolgreich aktualisiert.', icon: 'information' })
    } else {
      commit('setStatus', {
        message: `Gerät konnte nicht aktualisiert werden: [ ${ deleteProduct } ]`,
        icon: 'alert-circle-outline'
      })
    }
    commit('loadingStop')
  },

  async updateCustomerData({ commit, state }) {
    const { customer_data } = state
    const customerData = {
      data: {
        customer_number: customer_data.customer_number,
        title: null,
        salutation: null,
        first_name: customer_data.first_name,
        last_name: customer_data.last_name,
        street: customer_data.street,
        street_number: customer_data.street_number,
        postalcode: customer_data.postalcode,
        city: customer_data.city,
        country: 'DE',
        phone_private: customer_data.phone_private,
        phone_office: null,
        mobile_phone_private: customer_data.mobile_phone_private,
        mobile_phone_office: null,
        email_private: customer_data.email_private !== ('' || null) ? customer_data.email_private : undefined,
        email_office: null,
        birthdate: customer_data.birthdate
      }
    }

    const response = await engage.updateCustomer(customerData)

    if (response?.status === 200) {
      commit('setStatus', { message: 'Kundendaten erfolgreich aktualisiert', icon: 'check' })
    } else {
      commit('setStatus', { message: response, icon: 'alert-circle-outline' })
    }
  },

  async updateDeviceData({ commit, state }, cart_device_index = 0) {
    const currentDevice = state.selected_devices[cart_device_index]
    const currentDeviceType = currentDevice.current_device_class_object.type
    const cart_device_id = state.cart.products[cart_device_index].device.id

    const device_data = {
      purchase_price: currentDevice.purchase_price,
      purchase_date: currentDevice.purchased_at,
      model_name: isEmpty(currentDevice.type) ? currentDevice.name : currentDevice.type,
      condition: currentDevice.device_condition
    }

    if (!isEmpty(currentDevice.number)) device_data.serial_number = currentDevice.number
    if (!isEmpty(currentDevice.battery_serial_number)) device_data.battery_serial_number = currentDevice.battery_serial_number
    if (!isEmpty(currentDevice.current_manufacturer_object)) device_data.manufacturer = currentDevice.current_manufacturer_object.name
    if (!isEmpty(currentDevice.device_os)) device_data.os = currentDevice.device_os
    if (!isEmpty(currentDevice.number)) device_data.number = currentDevice.number
    if (currentDeviceType === 'smartphone') device_data.imei = currentDevice.number
    if (has(currentDevice, 'lock_price_confirm')) device_data.lock_price_confirm = currentDevice.lock_price_confirm

    const updateDevice = await insurance.updateDeviceData(cart_device_id, device_data)

    if (updateDevice?.status === 200) {
      commit('setCart', updateDevice.data.data)
      commit('setDeviceIsInCart', { index: cart_device_index, value: true })
      commit('setStatus', { message: 'Gerät erfolgreich im Cart aktualisiert.', icon: 'information' })
    } else {
      commit('setStatus', {
        message: `Gerät konnte nicht im Cart aktualisiert werden: [ ${ updateDevice } ]`,
        icon: 'alert-circle-outline'
      })
    }
  },

  async updatePaymentInterval({ commit }, payment_interval) {
    const updateInterval = await insurance.updatePaymentInterval(payment_interval)
    if (updateInterval?.status === 200) {
      commit('setCart', updateInterval.data.data)
      commit('setStatus', { message: 'Zahlungsintervall erfolgreich geändert.', icon: 'information' })
    } else {
      commit('setStatus', {
        message: `Zahlungsintervall konnte nicht geändert werden: [ ${ updateInterval } ]`,
        icon: 'alert-circle-outline'
      })
    }
  }
}

export default actions
