<template>
  <div class="mt-5">
    <div v-if="(deviceType !== 'bike' && deviceType !== 'ebike')">
      <v-checkbox v-model="isRefurbished"
                  label="Generalüberholtes Gerät?"
                  hide-details="auto"
                  @change="setDeviceCondition"
      />
    </div>

    <h4 class="mt-3 mb-1">Geräte-Zustand:</h4>
    <div class="d-flex align-center">
      <span class="pr-1 text-body-1">{{ deviceCondition.label }}</span>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small color="primary">
            mdi-information-outline
          </v-icon>
        </template>
        <div v-if="isRefurbished" class="text-caption">
          <p class="mb-0">
            Als "Refurbished" gelten Geräte, die ein Hersteller oder Händler generalüberholt, gereinigt und geprüft hat.
          </p>
        </div>
        <div v-else class="text-caption">
          <p class="mb-0">Geräte gelten als "Gebraucht" nach:</p>
          <ul>
            <li>CE-Geräte nach 24 Monaten</li>
            <li>Fahrräder nach 6 Monaten</li>
            <li>E-Bikes/Pedelecs nach 6 Monaten</li>
            <li>Akkus nach 12 Monaten</li>
            <li>Smartphones nach 12 Monaten</li>
          </ul>
          <p class="mt-2 mb-0">Errechnetes Gerätealter: {{ deviceAge }} Monate</p>
        </div>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import moment from 'moment/moment'
import { isEmpty } from 'lodash'

export default {
  name: 'DeviceCondition',
  props: {
    device: {},
    cart_index: {}
  },
  data: () => ({
    isRefurbished: false
  }),
  computed: {
    ...mapState(['cart']),
    ...mapGetters(['hasActiveCart']),
    deviceType() {
      return (!isEmpty(this.device?.current_device_class_object)) ? this.device?.current_device_class_object?.type : ''
    },
    maxAge() {
      const deviceType = this.device?.current_device_class_object?.type || null
      switch (deviceType) {
        case 'bike':
          return 6
        case 'ebike':
          return 6
        case 'battery':
          return 12
        case 'ce':
          return 24
        case 'smartphone':
          return 12
        default:
          return 12
      }
    },
    deviceAge() {
      return (!isEmpty(this.device.purchased_at))
          ? moment().diff(moment(this.device.purchased_at), 'months', true).toFixed(2)
          : 0
    },
    deviceCondition() {
      if (this.isRefurbished) {
        return { label: 'Refurbished', value: '3' }
      } else {
        return (this.deviceAge <= this.maxAge)
            ? { label: 'Neugerät', value: '1' }
            : { label: 'Gebrauchtgerät', value: '2' }
      }
    }
  },
  watch: {
    async deviceAge(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.setDeviceCondition()
      }
    }
  },
  async mounted() {
    await this.updateDevice({
      index: this.cart_index,
      device_data: {
        device_condition: this.deviceCondition.value
      }
    })
  },
  methods: {
    ...mapActions(['updateCartProduct']),
    ...mapMutations(['updateDevice']),
    async setDeviceCondition() {
      this.updateDevice({
        index: this.cart_index,
        device_data: {
          device_condition: this.deviceCondition.value
        }
      })

      if (this.hasActiveCart && this.cart?.products[this.cart_index] !== undefined) {
        await this.updateCartProduct(this.cart_index)
      }
    }
  }
}
</script>
