<template>
  <treeselect
      :class="{required: deviceClassRequired}"
      :options="filteredDeviceClasses"
      :value="device.current_device_class_object"
      valueFormat="object"
      :normalizer="normalizer"
      :label="device_classes.name"
      placeholder="Geräteklasse *"
      no-results-text='Keine passende Geräteklasse gefunden'
      noOptionsText="Keine Geräteklassen gefunden."
      :disable-branch-nodes="true"
      @input="onDeviceClassSelect"
      :disabled="device.is_in_cart || !device.is_new_device"
  />
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import { getFilteredDeviceClasses, getDeviceClassObjectById } from '@/store/helpers'
import { mapActions, mapMutations, mapState } from 'vuex'
import { isEmpty } from 'lodash'

export default {
  name: 'DeviceClass',
  components: { Treeselect },
  props: {
    cart_index: {},
    device: {},
    deviceClassRequired: {}
  },
  computed: {
    ...mapState(['device_classes', 'allowed_device_classes']),
    filteredDeviceClasses() {
      return getFilteredDeviceClasses(this.device_classes, this.allowed_device_classes)
    }
  },
  methods: {
    ...mapActions(['getProductOffers', 'getManufacturers']),
    ...mapMutations(['updateDevice', 'setCurrentDeviceClassId']),
    async fetchManufacturers(winsure_id) {
      await this.getManufacturers({
        winsure_id: winsure_id,
        index: this.cart_index
      })

      if (!isEmpty(this.device.manufacturer)) {
        this.updateDevice({
          index: this.cart_index,
          device_data: {
            current_manufacturer_object: {
              id: parseInt(this.device.manufacturer),
              name: this.device.manufacturers.find(obj => obj.id === parseInt(this.device.manufacturer)).name
            }
          }
        })
      }
    },
    normalizer(node) {
      return {
        label: node.name
      }
    },
    async onDeviceClassSelect(node) {
      if (!isEmpty(node)) {
        if (node.insurable) await this.getProductOffers(node.id)

        this.updateDevice({
          index: this.cart_index,
          device_data: {
            current_device_class_object: getDeviceClassObjectById(this.device_classes, node.id),
            selected_components: []
          }
        })
        this.setCurrentDeviceClassId(node.id)
        await this.fetchManufacturers(node.winsure_id)
        this.$emit('device-class-selected')
      } else {
        this.updateDevice({
          index: this.cart_index,
          device_data: {
            current_device_class_object: null
          }
        })
      }
    }
  }
}
</script>
