<template>
    <div>
        <h4 class="mt-5">Premium-Optionen:</h4>
        <v-checkbox
                v-for="premium in device.premiums"
                v-model="premium.is_checked"
                :disabled="premium.required || isDisabled"
                :label="premium.name"
                :key="premium.id"
                @change="onPremiumChange($event, premium.id)"
                hide-details
                class="mt-1"
        />
    </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'Premiums',
  props: {
    device: {},
    cart_index: {},
    deviceIsUpdating: {},
    priceIsUpdating: {}
  },
  data: () => ({
    premiumsAreUpdating: false
  }),
  computed: {
    isDisabled() {
      return this.device.is_in_cart
        || this.deviceIsUpdating
        || this.priceIsUpdating
        || this.premiumsAreUpdating
    }
  },
  methods: {
    ...mapActions(['addCartPremium', 'deleteCartPremium']),
    async onPremiumChange(value, premium_id) {
      const payload = { premium_product_id: premium_id, cart_index: this.cart_index }
      this.premiumsAreUpdating = true
      if (value) {
        await this.addCartPremium(payload)
      } else {
        await this.deleteCartPremium(payload)
      }
      this.premiumsAreUpdating = false
    }
  }
}
</script>
