<template>
  <v-row>
    <v-col cols="8">
      <v-form lazy-validation v-model="isValid" ref="personalDataForm">
        <section>
          <v-row>
            <v-col class="pt-1">
              <h3>Neuvertrag - Persönliche Daten</h3>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-select :items="this.salutationItems"
                        dense
                        label="Anrede"
                        hide-details
                        outlined
                        :value="customer.salutation"
                        @change="updateCustomerData('salutation', $event)"
                        :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field dense label="Vorname *"
                            hide-details="auto"
                            outlined
                            :value="customer.first_name"
                            @blur="updateCustomerData('first_name', $event.target.value)"
                            :rules="[rules.required]"
                            @keypress.enter="submitData"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field dense label="Nachname *"
                            hide-details="auto"
                            outlined
                            :value="customer.last_name"
                            @blur="updateCustomerData('last_name', $event.target.value)"
                            :rules="[rules.required]"
                            @keypress.enter="submitData"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field type="date" dense label="Geburtsdatum *"
                            hide-details="auto"
                            outlined
                            :value="customer.birthdate"
                            @blur="updateCustomerData('birthdate', $event.target.value)"
                            :rules="[rules.required, rules.birthdate]"
                            @keypress.enter="submitData"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <v-text-field dense label="Straße *"
                            hide-details="auto"
                            outlined
                            :value="customer.street"
                            @blur="updateCustomerData('street', $event.target.value)"
                            :rules="[rules.required]"
                            @keypress.enter="submitData"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field dense label="Hausnr. *"
                            hide-details="auto"
                            outlined
                            :value="customer.street_number"
                            @blur="updateCustomerData('street_number', $event.target.value)"
                            :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field dense type="number" max="99999" label="PLZ *"
                            hide-details="auto"
                            outlined
                            :value="customer.postalcode"
                            @blur="updateCustomerData('postalcode', $event.target.value)"
                            :rules="[rules.required, rules.plz]"
                            @keypress.enter="submitData"
              />
            </v-col>
            <v-col cols="5">
              <v-text-field dense label="Ort *"
                            hide-details="auto"
                            outlined
                            :value="customer.city"
                            @blur="updateCustomerData('city', $event.target.value)"
                            :rules="[rules.required]"
                            @keypress.enter="submitData"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field dense label="Telefon (privat)"
                            hide-details="auto"
                            outlined
                            :value="customer.phone_private"
                            @blur="updateCustomerData('phone_private', $event.target.value)"
                            @keypress.enter="submitData"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field dense label="Telefon (mobil)"
                            hide-details="auto"
                            outlined
                            :value="customer.mobile_phone_private"
                            @blur="updateCustomerData('mobile_phone_private', $event.target.value)"
                            @keypress.enter="submitData"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field dense label="E-Mail (privat) *"
                            hide-details="auto"
                            outlined
                            :value="customer.email_private"
                            @blur="updateCustomerData('email_private', $event.target.value)"
                            @keypress.enter="submitData"
                            :rules="[rules.required, rules.email]"

              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" class="text-right" offset="8">
              <v-btn color="primary"
                     @click="submitData"
                     @keypress.enter="submitData"
                     :disabled="hasSelectedProduct"
              >
                <v-icon left>mdi-check</v-icon>
                Daten Übernehmen
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="text-right">
              <p class="caption mb-0 mr-1">* = Pflichtfeld</p>
            </v-col>
          </v-row>
        </section>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import moment from 'moment'
import { isEmpty } from 'lodash'

export default {
  name: 'new-contract-personal-data',
  data: () => ({
    salutationItems: ['Herr', 'Frau', 'Divers'],
    rules: {
      required: value => !!value || '* Pflichtfeld.',
      birthdate: value => {
        return (moment().diff(value, 'years', true) > 18) || 'Mindestalter: 18 Jahre'
      },
      plz: value => {
        return (value) ? value.length === 5 || 'PLZ muss fünfstellig sein' : false
      },
      email: value => {
        const mailpattern = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$')
        return (value) ? mailpattern.test(value) || 'E-Mail muss gültig sein' : false
      }
    },
    isValid: false
  }),
  computed: {
    ...mapState({ customer: state => state.customer_data }),
    ...mapGetters(['hasSelectedProduct'])
  },
  methods: {
    ...mapMutations(['updateCustomer', 'setHasValidCustomerData', 'addEmptyDevice']),
    updateCustomerData(key, value) {
      this.updateCustomer({ [key]: value })
    },
    submitData() {
      // Safari workaround
      if (isEmpty(this.customer.birthdate)) {
        this.updateCustomerData('birthdate', moment().format('YYYY-MM-DD'))
      }
      this.isValid = this.$refs.personalDataForm.validate()

      if (this.isValid) {
        this.updateCustomerData('payment_method', 'direct_debit')
        this.updateCustomerData('payment_iban', null)
        this.updateCustomerData('customer_number', null)
        this.setHasValidCustomerData(true)
        this.addEmptyDevice()
      } else {
        this.setHasValidCustomerData(false)
      }
    }
  }
}
</script>
