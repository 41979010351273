<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <img src="../assets/h100w.png" alt="HYVE" class="d-inline" />
      <span class="pl-3 pt-1 text-h5">ENGAGE</span>
      <v-tabs class="ml-10">
        <v-tab to="/">HOME</v-tab>
        <v-tab to="/journal">JOURNAL</v-tab>
        <v-tab to="/user" v-if="isAdmin">BENUTZER</v-tab>
      </v-tabs>
      <v-spacer />
      <div class="pr-4 user_info">
        <span v-if="countdown" class="logout_counter">Automatischer Logout in: {{ formatTime(countdown) }}</span>
        Angemeldet als: {{ fullName }}
      </div>
      <v-btn color="blue" @click="onLogoutClick">Ausloggen</v-btn>
    </v-app-bar>
    <v-main>
      <v-container fluid class="pt-3">
        <router-view />
      </v-container>
    </v-main>
    <v-footer
        fixed
        elevation="3"
        :color="footerColor"
        :dark="(icon && icon !=='information')"
    >
      <v-icon
          v-if="message !== null"
          :color="(icon === 'information') ? 'blue' : 'white'">
        mdi-{{ icon }}
      </v-icon>
      <span class="pl-3">{{ message }}</span>
      <v-spacer />
      <div class="version_info">Version: {{ version }}</div>
    </v-footer>
  </v-app>

</template>
<script>

import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'Home',
  data: () => ({
    timeToLogout: 900, // in seconds
    timeToCountdownStart: 30 , // in seconds
    version: process.env.VUE_APP_VERSION,
    timer: null,
    countdown: 0
  }),
  computed: {
    ...mapState({
      token: state => state.user.engage_token,
      message: state => state.status.message,
      icon: state => state.status.icon,
      fullName: state => `${ state.user.first_name } ${ state.user.last_name }`,
      isAdmin: state => state.user.is_admin
    }),
    ...mapGetters(['footerColor'])
  },
  async created() {
    if (this.token === null) {
      await this.$router.push('/login')
    }
  },
  mounted() {
    document.addEventListener('mousemove', this.resetCountdown)
    document.addEventListener('keydown', this.resetCountdown)
    document.addEventListener('click', this.resetCountdown)

    this.timer = setTimeout(this.startCountdown, this.timeToCountdownStart * 1000)
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.resetCountdown)
    document.removeEventListener('keydown', this.resetCountdown)
    document.removeEventListener('click', this.resetCountdown)
    clearInterval(this.timer)
  },
  methods: {
    ...mapActions(['logoutUser']),
    formatTime(timeInSeconds) {
      const minutes = Math.floor(timeInSeconds / 60)
      const seconds = timeInSeconds % 60
      return `${ minutes.toString().padStart(2, '0') }:${ seconds
          .toString()
          .padStart(2, '0') }`
    },
    startCountdown() {
      this.countdown = this.timeToLogout
      this.timer = setInterval(() => {
        this.countdown -= 1
        if (this.countdown === 0) {
          clearInterval(this.timer)
          this.countdown = null
          this.onLogoutClick()
        }
      }, 1000)
    },
    resetCountdown() {
      clearInterval(this.timer)
      this.countdown = 0
      this.timer = setTimeout(this.startCountdown, this.timeToCountdownStart * 1000)
    },
    async onLogoutClick() {
      await this.logoutUser()
      await this.$router.push('/login')
    }
  }
}
</script>
