<template>
  <v-row>
    <v-col cols="10" offset="1" class="mb-10">
      <v-card class="px-8 py-5" elevation="3">
        <customer-number />
        <v-divider class="my-5" />
        <personal-data v-if="hasCustomerData && !isNewContract" />
        <new-contract-personal-data v-if="isNewContract" />

        <v-divider class="my-5" v-if="hasCustomerData" />

        <v-row v-if="hasCustomerData && !isNewContract" align="start">
          <v-col>
            <v-btn color="primary" @click="addNewDevice" :disabled="addNewDeviceAllowed">
              <v-icon left>mdi-fan-plus</v-icon>
              Neues Gerät anlegen
            </v-btn>
          </v-col>
        </v-row>

        <existing-devices v-if="hasCustomerData && !isNewContract" />

        <v-divider class="mb-8" v-if="(hasProductOffers && !isNewContract) || isFirstDevice" />

        <v-row v-if="hasCustomerData && (isNewContract || isFirstDevice) && !hasActiveCart"
               align="center"
               no-gutters
               id="new-contract-product-selection"
        >
          <new-contract-device-class />
        </v-row>

        <v-divider class="mb-8" v-if="hasProductOffers && (isNewContract || isFirstDevice) && !hasActiveCart" />

        <v-row v-if="hasProductOffers && !cartHasActive3for2Promotion" id="product-selection">
          <v-col>
            <v-row align="center" no-gutters>
              <v-col>
                <h3>Wählbare Tarife</h3>
              </v-col>
            </v-row>

            <v-row no-gutters id="product-select">
              <v-col>
                <v-radio-group :value="selected_product" row @change="handleProductSelect">
                  <v-radio v-for="product in getProductsForSelectedDevice" :key="product.value"
                           :value="product" :disabled="hasSelectedProduct">
                    <template v-slot:label>
                      <div><strong>{{ product.name }}</strong></div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-divider class="mb-8" v-if="hasCustomerData && !cartHasActive3for2Promotion" />

        <v-row dense v-if="hasProductOffers && hasSelectedProduct" id="contract">
          <contract v-if="has_valid_customer_data" />
        </v-row>

        <v-divider class="my-8" v-if="show_mail_communication" />
        <mail-communication v-if="show_mail_communication" />

        <v-row>
          <v-col>
            <v-btn
                color="primary"
                class="mx-auto"
                v-if="checkout_done"
                @click="restart"
            >
              <v-icon left>mdi-arrow-u-right-top</v-icon>
              Neu beginnen
            </v-btn>

          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-overlay color="primary" :opacity=".5" :value="is_loading">
      <v-progress-circular indeterminate size="128">
        Bitte warten...
      </v-progress-circular>
    </v-overlay>
  </v-row>
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CustomerNumber from '@/components/customer-number'
import PersonalData from '@/components/personal-data'
import ExistingDevices from '@/components/existing-devices'
import NewContractPersonalData from '@/components/new-contract-personal-data'
import Contract from '@/components/contract'
import NewContractDeviceClass from '@/components/new-contract-device-class'
import MailCommunication from '@/components/mail-communication'
import { isEmpty } from 'lodash'

export default {
  name: 'Engage',
  components: {
    MailCommunication,
    NewContractDeviceClass,
    Contract,
    NewContractPersonalData,
    ExistingDevices,
    PersonalData,
    CustomerNumber
  },
  computed: {
    ...mapState(['active_device_id', 'current_device_id', 'selected_product', 'is_loading', 'has_valid_customer_data', 'show_mail_communication', 'checkout_done']),
    ...mapGetters([
      'hasCustomerData', 'isNewContract', 'currentDeviceDetails', 'hasProductOffers', 'isFirstDevice',
      'getProductsForSelectedDevice', 'hasSelectedProduct', 'hasActiveCart', 'cartHasActive3for2Promotion',
      'hasSelectedDevices', 'hasProductQuote'
    ]),
    addNewDeviceAllowed() {
      return this.selected_product?.value === 'komplettschutz'
    }
  },
  watch: {
    getProductsForSelectedDevice(products) {
      if (products.length === 1) {
        this.handleProductSelect(products[0])
      }
    }
  },
  methods: {
    ...mapActions(['selectProduct', 'getAllowedDeviceClasses']),
    ...mapMutations(['addEmptyDevice', 'setIsFirstDevice', 'resetState']),
    addNewDevice() {
      if (this.hasSelectedDevices) {
        this.addEmptyDevice()
      } else {
        this.setIsFirstDevice(true)
        this.scrollToNewContractProductSelection()
      }
    },
    async handleProductSelect(product) {
      await this.selectProduct(product)
      this.scrollToDevices()
    },
    restart() {
      this.resetState()
      this.$nextTick(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      })
    },
    scrollToDevices() {
      const devices = document.getElementById('devices')

      if (!isEmpty(devices)) {
        this.$nextTick(() => {
          devices.scrollIntoView({ behavior: 'smooth' })
        })
      }
    },
    scrollToNewContractProductSelection() {
      this.$nextTick(() => {
        document.getElementById('new-contract-product-selection').scrollIntoView({ behavior: 'smooth' })
      })
    }
  }
}
</script>
