<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="25%">
      <v-card elevation="2">
        <v-card-title class="subtitle-1 primary white--text px-5 py-2">Komponenten hinzufügen (max.
          {{ maxAllowed }})
        </v-card-title>
        <v-card-text class="pt-7">
          <v-expansion-panels accordion inset>
            <v-expansion-panel
                v-for="(component,i) in currentComponents"
                :key="i"
            >
              <v-expansion-panel-header color="grey lighten-4" :disable-icon-rotate="component.is_in_cart">
                {{ component.name }}
                <template v-slot:actions v-if="component.is_in_cart">
                  <v-icon color="teal">
                    mdi-check
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="">
                <v-form lazy-validation v-model="component.is_valid" :ref="`componentForm${i}`">
                  <v-text-field label="Hersteller *"
                                v-model="component.manufacturer"
                                hide-details="auto"
                                :rules="[rules.required]"
                                @keypress.enter="component.is_in_cart ? updateComponent(i) : addComponent(i)"
                  />
                  <v-select
                      :items="allComponentData.components"
                      :value="componentName(component.component_class_id)"
                      item-text="name"
                      item-value="id"
                      return-object
                      label="Komponente *"
                      hide-details="auto"
                      :rules="[rules.required]"
                      @change="handleComponentSelect($event, i)"
                  />
                  <v-text-field type="number"
                                v-model="component.purchase_price"
                                label="Kaufpreis *"
                                hide-details="auto"
                                append-icon="mdi-currency-eur"
                                :rules="[rules.required, rules.price]"
                  />
                  <v-text-field type="date"
                                v-model="component.purchase_date"
                                label="Kaufdatum *"
                                hide-details="auto"
                                :rules="[rules.required]"
                                :max="maxDate"
                  />
                  <v-text-field label="Modell / Typ"
                                v-model="component.model_name"
                                hide-details="auto"
                                @keypress.enter="component.is_in_cart ? updateComponent(i) : addComponent(i)"
                  />
                  <v-text-field label="Seriennummer"
                                v-model="component.serial_number"
                                hide-details="auto"
                                @keypress.enter="component.is_in_cart ? updateComponent(i) : addComponent(i)"
                  />

                  <div class="d-flex mt-5">
                    <v-btn class="px-0" color="primary" text :loading="isLoading"
                           @click.stop="component.is_in_cart ? updateComponent(i) : addComponent(i)">
                      {{ component.is_in_cart ? 'Aktualisieren' : 'Hinzufügen' }}
                    </v-btn>
                    <v-spacer />
                    <v-btn class="px-0" color="red" text :disabled="isLoading" @click.stop="deleteComponent(i)">
                      Löschen
                    </v-btn>
                  </div>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <confirm ref="confirm" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.stop="handleCancel">
            Schliessen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { isEmpty } from 'lodash'
import Confirm from '@/components/confirm'

export default {
  name: 'component-configurator',
  components: { Confirm },
  props: {
    value: Boolean,
    cart_index: Number
  },
  data: () => ({
    dialog: false,
    hasData: false,
    isLoading: false,
    isValid: false,
    rules: {
      required: value => !!value || '* Pflichtfeld.',
      price: value => (value !== null && parseFloat(value) <= 10000) || 'Maximaler Kaufpreis: 10.000,- €'
    },
    currentComponents: []
  }),
  computed: {
    ...mapState(['selected_devices', 'cart']),
    allComponentData() {
      return this.selected_devices[this.cart_index].current_device_class_object.component_data
    },
    cartComponents() {
      return this.cart.products[this.cart_index].device.components
    },
    cartDeviceId() {
      return this.cart.products[this.cart_index].device.id
    },
    maxAllowed() {
      return this.allComponentData.max_components
    },
    minDate() {
      const date = new Date()
      date.setMonth(date.getMonth() - 2)
      return date.toISOString().split('T')[0]
    },
    maxDate() {
      return new Date().toISOString().split('T')[0]
    },
    selectedComponents() {
      return this.selected_devices[this.cart_index].selected_components
    },
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    selectedComponents() {
      this.migrateComponentData()
    }
  },
  methods: {
    ...mapActions(['addCartComponent', 'updateCartComponent', 'deleteCartComponent']),
    async addComponent(i) {
      if (this.validateForm(i)) {
        const payload = this.mapComponentData(i)
        this.isLoading = true
        await this.addCartComponent(payload)
        this.isLoading = false
      }
    },
    componentName(component_class_id) {
      return this.allComponentData.components.find(component => component.id === component_class_id)
    },
    async deleteComponent(i) {
      const currentForm = this.$refs[`componentForm${ i }`][0]
      const cart_component_id = this.selectedComponents[i].cart_component_id

      if (await this.$refs.confirm.open('Komponente löschen',
          `Wollen Sie wirklich die Komponente löschen?`,
          { color: 'red' })) {
        this.isLoading = true

        await this.deleteCartComponent({ cart_component_id, cart_index: this.cart_index })

        this.currentComponents = []
        this.populateSelectedComponents()
        currentForm.resetValidation()

        this.isLoading = false
      }
    },
    handleCancel() {
      this.currentComponents = []
      this.show = false
    },
    handleComponentSelect(component, index) {
      this.currentComponents[index].component_class_id = component.id
      this.currentComponents[index].name = component.name
    },
    isInCart(id) {
      return this.selectedComponents.find(el => el.component_class_id === id).is_in_cart || false
    },
    mapComponentData(i) {
      const current = this.currentComponents[i]
      return {
        cart_component_id: null,
        cart_device_id: this.cartDeviceId,
        cart_index: this.cart_index,
        condition: current.condition,
        component_class_id: current.component_class_id,
        component_index: i,
        is_in_cart: current.is_in_cart,
        manufacturer: current.manufacturer,
        model_name: current.model_name,
        name: current.name,
        purchase_date: current.purchase_date,
        purchase_price: current.purchase_price,
        serial_number: current.serial_number
      }
    },
    migrateComponentData() {
      if (!isEmpty(this.selectedComponents)) {
        this.selectedComponents.forEach((component, i) => {
          this.currentComponents[i] = this.selectedComponents[i]
        })
      }
    },
    populateSelectedComponents() {
      const count = isEmpty(this.selectedComponents) ? this.maxAllowed : this.maxAllowed - this.selectedComponents.length
      let index = isEmpty(this.selectedComponents) ? 1 : this.selectedComponents.length + 1

      this.selectedComponents.forEach((component, i) => {
        this.currentComponents[i] = this.selectedComponents[i]
      })

      for (let i = 0; i < count; i++) {
        this.currentComponents.push({
          condition: 1,
          id: null,
          is_in_cart: false,
          is_valid: false,
          manufacturer: '',
          model_name: null,
          name: 'Komponente ' + (index),
          purchase_date: null,
          purchase_price: null,
          serial_number: null
        })
        index++
      }
    },
    async updateComponent(i) {
      if (this.validateForm(i)) {
        this.isLoading = true
        const payload = this.mapComponentData(i)
        payload.cart_component_id = this.cartComponents[i].id
        await this.updateCartComponent(payload)
        this.isLoading = false
      }
    },
    validateForm(i) {
      const currentForm = this.$refs[`componentForm${ i }`][0]
      this.currentComponents[i].is_valid = currentForm.validate()
      return this.currentComponents[i].is_valid
    }
  }
}
</script>
