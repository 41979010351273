import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Journal from '@/views/Journal'
import Engage from '@/views/Engage'
import User from '@/views/User'
import store from "../store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '',
        component: Engage
      },
      {
        path: '/journal',
        component: Journal
      },
      {
        path: '/user',
        component: User,
        beforeEnter: (to, from, next) => {
          if (store.state.user.is_admin) {
            next()
          } else {
            next({ path: '/' })
          }
        },
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
