<template>
  <v-row id="mail_communication">
    <v-col>
      <v-row>
        <v-col class="pt-1">
          <h3>E-Mail-Kommunikation</h3>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-select
              :items="templateItems"
              v-model="displayed_template"
              item-value="id"
              item-text="title"
              @change="onChangeEmailTemplate"
              dense
              label="E-Mail Vorlage"
              return-object
              outlined
              hide-details
          />
        </v-col>
        <v-col cols="4" offset="1">
          <v-text-field
              v-model="current_email"
              dense
              label="E-Mail"
              hide-details="auto"
              outlined
              :rules="[rules.required, rules.mail]"
          />
        </v-col>
      </v-row>

      <v-row class="mb-5">
        <v-col cols="9">
          <v-textarea
              height="400"
              outlined
              hide-details
              label="E-Mail"
              v-model="parsed_email"
          />
        </v-col>
        <v-col cols="3">
          <v-card class="pa-5 d-flex flex-column justify-space-between" elevation="1" height="400">
            <v-checkbox
                class="mt-0"
                v-model="summaryLock"
                label="Vertragsoptionen gesperrt"
                hide-details
                @change="onSummaryLockClick"
            />

            <v-checkbox
                class="mt-2"
                v-model="sendNotification"
                label="Erinnerungsfunktion"
                hide-details
                @change="onReminderClick"
            />

            <v-checkbox
                class="mt-2"
                v-model="withAttachments"
                label="Anhänge mitsenden"
                hide-details
                @change="(value) => this.setCommunicationSettings({with_attachments: value})"
            />

            <v-spacer />

            <v-btn
                color="primary"
                class="mx-auto"
                :disabled="!isValidMail || mailIsSending"
                @click="sendMail"
            >
              <v-icon left>mdi-email-arrow-right</v-icon>
              Mail versenden
            </v-btn>

            <confirm ref="confirm" />
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>


<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { isValidEmail, parseTpl } from '@/store/helpers'
import { isEmpty } from 'lodash'
import Confirm from '@/components/confirm'

export default {
  name: 'mail-communication',
  components: { Confirm },
  data: () => ({
    current_email: null,
    displayed_template: { body: null },
    templateItems: [],
    mailIsSending: false,
    parsed_reminder_email: null,
    parsed_email: null,
    summaryLock: false,
    sendNotification: false,
    confirmMailSend: false,
    withAttachments: false,
    rules: {
      required: value => !!value || '* Pflichtfeld.',
      mail: value => (value !== null && isValidEmail(value)) || 'Ungültige E-Mail'
    }
  }),
  computed: {
    ...mapState(['email_templates', 'customer_data', 'current_employee']),
    ...mapGetters(['getDeviceNamesForEmail']),
    isValidMail() {
      return this.current_email !== null && isValidEmail(this.current_email)
    }
  },
  watch: {
    'customer_data.salutation': function () {
      this.parseEmails()
    },
    'customer_data.first_name': function () {
      this.parseEmails()
    },
    'customer_data.last_name': function () {
      this.parseEmails()
    },
    'current_employee': function () {
      this.parseEmails()
    }
  },
  mounted() {
    this.filterTemplates()
    this.current_email = this.getCurrentEmailAdress()
  },
  methods: {
    ...mapActions(['checkoutEngage']),
    ...mapMutations(['setCommunicationSettings', 'setEmailData', 'setReminderEmailData', 'updateCustomer']),
    filterTemplates() {
      this.templateItems = this.email_templates.filter(template => !template.type.includes('_reminder'))

      if (this.summaryLock) {
        this.templateItems = this.templateItems.filter(template => template.type.includes('_flex'))
      } else if (this.sendNotification) {
        this.templateItems = this.templateItems.filter(template => template.type.includes('_reminder'))
      }

      this.displayed_template = this.templateItems[0]
      this.parseEmails()
    },
    getCurrentEmailAdress() {
      return isEmpty(this.customer_data.email_private) ? null : this.customer_data.email_private
    },
    async sendMail() {
      if (await this.$refs.confirm.open('E-Mail versenden',
          `E-Mail wirklich versenden?`,
          { color: 'red' })) {

        if (this.isValidMail) {
          if (isEmpty(this.customer_data.email_private)) {
            this.updateCustomer({ email_private: this.current_email })
          }

          this.setEmailData({
            recipient: this.current_email,
            subject: this.displayed_template.subject,
            body: this.parsed_email
          })

          this.setReminderEmailData({
            recipient: this.current_email,
            subject: this.displayed_template.subject,
            body: this.parsed_reminder_email
          })

          this.mailIsSending = true
          await this.checkoutEngage('prefill')
        }
      }
    },
    onChangeEmailTemplate() {
      this.parseEmails()
    },
    onReminderClick(value) {
      this.setCommunicationSettings({ reminder_mails: value })
      this.parseEmails()
    },
    onSummaryLockClick(value) {
      this.setCommunicationSettings({ skip_to_summary: value, prevent_step_change: value })
      this.filterTemplates()
    },
    parseEmails() {
      const reminderTemplate = this.email_templates.find(tpl => tpl.type.includes('_reminder'))

      this.parsed_email = this.parseTemplate(this.displayed_template.body)
      this.parsed_reminder_email = this.parseTemplate(reminderTemplate.body)
    },
    parseTemplate(template) {
      const formattedTemplate = template.replace(/<br\s*[/]?>/gi, '\r\n')

      return parseTpl(formattedTemplate, {
        salutation: this.customer_data.salutation,
        firstname: this.customer_data.first_name,
        lastname: this.customer_data.last_name,
        device: this.getDeviceNamesForEmail,
        employee: `${ this.current_employee.first_name } ${ this.current_employee.last_name }`
      })
    }
  }
}
</script>
