<template>
  <v-text-field label="Modell"
                hide-details="auto"
                :value="deviceModel"
                :rules="device.device_os === 'other' ? [rules.required] : []"
                @blur="updateDeviceModel"
                :disabled="device.is_in_cart"
  />
</template>
<script>
import { isEmpty } from 'lodash'
import { mapMutations } from 'vuex'

export default {
  name: 'DeviceModel',
  props: {
    device: {},
    cart_index: {}
  },
  data: () => ({
    rules: {
      required: value => !!value || '* Pflichtfeld.'
    }
  }),
  computed: {
    deviceModel() {
      return isEmpty(this.device.type) ? this.device.name : this.device.type
    }
  },
  methods: {
    ...mapMutations(['updateDevice']),
    async updateDeviceModel(e) {
      const value = e.target.value

      if (value !== '' && this.device.type !== value) {
        await this.updateDevice({
          index: this.cart_index,
          device_data: { type: value }
        })
      }
    }
  }
}
</script>
