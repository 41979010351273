import axios from 'axios'

const isProd = () => process.env.VUE_APP_ENVIRONMENT === 'production'
const baseURL = isProd() ? process.env.VUE_APP_ENGAGE_PROD_URL : process.env.VUE_APP_ENGAGE_STAGE_URL
const passwordClientId = isProd() ? process.env.VUE_APP_ENGAGE_PROD_PASSWORD_CLIENT_ID : process.env.VUE_APP_ENGAGE_STAGE_PASSWORD_CLIENT_ID
const passwordClientSecret = isProd() ? process.env.VUE_APP_ENGAGE_PROD_PASSWORD_CLIENT_SECRET : process.env.VUE_APP_ENGAGE_STAGE_PASSWORD_CLIENT_SECRET

const api = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const engage = {
  async checkout(checkoutData) {
    try {
      return await api.post('/api/v1/checkout', checkoutData, this._getAuthHeader())
    } catch (e) {
      return this._reportError('checkout', e)
    }
  },
  async getCustomer(customer_number) {
    try {
      return await api.get(`/api/v1/customers/${ customer_number }?include=contracts.devices`, this._getAuthHeader())
    } catch (e) {
      console.error('getCustomer failed:', e.message)
      return `Kundendaten für Kundennr. '${ customer_number }' konnten nicht geladen werden. (${ e.message })`
    }
  },

  async getCustomerByContract(formData) {
    try {
      return await api.post('/api/v1/customers/by-contract', formData, this._getAuthHeader())
    } catch (e) {
      console.error('getCustomerByContract failed:', e.message)
      return `Kundendaten für Vertragsnr. '${ formData.get('contract_number') }' konnten nicht geladen werden. (${ e.message })`
    }
  },

  async getEmailTemplates(filter) {
    try {
      return await api.get(`/api/v1/email-templates?filter[type]=${ filter }`, this._getAuthHeader())
    } catch (e) {
      return this._reportError('getEmailTemplates', e)
    }
  },

  async getManufacturers(winsure_id) {
    try {
      return await api.get(`/api/v1/winsure-suppliers?filter[winsure_devices.id]=${ winsure_id }`, this._getAuthHeader())
    } catch (e) {
      return this._reportError('getManufacturers', e)
    }
  },

  async login(username, password) {
    try {
      const loginData = {
        grant_type: 'password',
        client_id: passwordClientId,
        client_secret: passwordClientSecret,
        username: username,
        password: password,
        scope: ''
      }

      return await api.post(`/api/auth/login`, loginData)
    } catch (e) {
      console.error('Login failed:', e)
      return `Login nicht erfolgreich. (${ e.message })`
    }
  },

  async updateCustomer(customerData) {
    try {
      return await api.put('/api/v1/customers', customerData, this._getAuthHeader())
    } catch (e) {
      return this._reportError('updateCustomer', e)
    }
  },

  async getJournalIssues(employee_id) {
    try {
      return await api.get('/api/v1/issues' + (employee_id ? ('?employee_id=' + employee_id) : ''), this._getAuthHeader())
    } catch (e) {
      return this._reportError('getJournalIssues', e)
    }
  },

  async getJournalIssue(id) {
    try {
      return await api.get(`/api/v1/issues/` + id + `?include=statuses`, this._getAuthHeader())
    } catch (e) {
      return this._reportError('getJournalIssue', e)
    }
  },

  async getEmployees() {
    try {
      return await api.get('/api/v1/employees', this._getAuthHeader())
    } catch (e) {
      return this._reportError('getEmployees', e)
    }
  },

  async addEmployee(employeeData) {
    try {
      return await api.post('/api/v1/employees', employeeData, this._getAuthHeader())
    } catch (e) {
      return this._reportError('addEmployee', e)
    }
  },

  async updateEmployee(employeeData, id) {
    try {
      return await api.put('/api/v1/employees/' + id, employeeData, this._getAuthHeader())
    } catch (e) {
      return this._reportError('updateEmployee', e)
    }
  },

  async archiveStatus(issue_id) {
    try {
      return await api.post('/api/v1/statuses', {
        issue_id,
        type: 'archived',
        payload: {
          data: [{
            message: 'Der Vorgang wurde vom Mitarbeiter geschlossen.'
          }]
        }
      }, this._getAuthHeader())
    } catch (e) {
      return this._reportError('archiveStatus', e)
    }
  },

  _getAuthHeader() {
    const user = JSON.parse(sessionStorage.getItem('vuex')).user
    return {
      headers: {
        Authorization: 'Bearer ' + user.engage_token
      }
    }
  },

  _reportError(text, e) {
    if (e.response) {
      console.error(`${ e.response.status } - ${ text } failed`)
      return e.response.data.message
    } else {
      console.error(`${ text } failed:`, e)
      return `Error executing "${ text }". (${ e.message })`
    }
  }
}

export default engage
