import { isEmpty, omit, has } from 'lodash'
import { getDeviceClassIdByWinsureId, getProductsForSelectedDevice, getFooterColor } from '@/store/helpers'

const getters = {
  cartHasActive3for2Promotion: state => !isEmpty(state.cart.promotions),
  cartHasActiveDiscount: state => has(state.cart, 'products[0].discounts') && !isEmpty(state.cart.products[0].discounts),
  conclusionAllowed: state => state.selected_devices.every(device => device.is_in_cart),
  currentContractData: state => device_id => omit(state.contracts.find(contract => contract.devices.includes(device_id)), 'devices'),
  currentDeviceDetails: state => device_id => state.devices.find(device => device.id === device_id),
  footerColor: state => getFooterColor(state.status.icon),
  getDefaultEmployee: state => state.employees.find(employee => employee.id === state.user.id),
  getEmployees: state => state.employees,
  getDeviceClassIdByWinsureId: state => winsure_id => getDeviceClassIdByWinsureId(state.device_classes, winsure_id),
  getDeviceNamesForEmail: state => {
    if (isEmpty(state.selected_devices) || isEmpty(state.selected_devices[0].current_manufacturer_object)) return ''
    if (isEmpty(state.cart.promotions)) {
      const manufacturer = state.selected_devices[0].current_manufacturer_object.name
      const deviceName = state.selected_devices[0].current_device_class_object.name
      return `Ihr folgendes Gerät: ${ manufacturer } ${ deviceName }\n\n`
    } else {
      let deviceList = 'Ihre folgenden Geräte:\n'
      deviceList += state.selected_devices.map(device => `- ${ device.current_manufacturer_object.name } ${ device.current_device_class_object.name } \n`).join('')
      deviceList += '\n'
      return deviceList
    }
  },
  getProductsForSelectedDevice: state => getProductsForSelectedDevice(state.product_offers),
  getSelectedDevicesCount: state => state.selected_devices.length,
  hasActiveCart: state => !isEmpty(state.cart),
  hasCustomerData: state => state.has_valid_customer_data,
  hasInsuranceToken: state => !isEmpty(state.insurance_token),
  hasProductOffers: state => !isEmpty(state.product_offers),
  hasProductQuote: state => !isEmpty(state.product_quote),
  hasSelectedDevices: state => !isEmpty(state.selected_devices),
  hasSelectedProduct: state => !isEmpty(state.selected_product),
  is3for2: state => !isEmpty(state.selected_product) && state.selected_product.value === 'komplettschutz_3_für_2',
  isAkkuschutz: state => !isEmpty(state.selected_product) && state.selected_product.value === 'akkuschutz',
  isInCart: state => cart_index => {
    if (!isEmpty(state.cart) && !isEmpty(state.cart.products[cart_index])) {
      return state.cart.products[cart_index].device.device_class_id === state.selected_devices[cart_index].current_device_class_object.id
    } else return false

  },
  isNewContract: state => state.is_new_contract,
  isFirstDevice: state => state.is_first_device
}

export default getters
