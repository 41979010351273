<template>
  <div>
    <div v-if="device_type === 'ebike'">
      <v-text-field label="Akku-Seriennummer"
                    hide-details="auto"
                    :value="device.battery_serial_number"
                    :rules="isAkkuschutz ? [rules.required] : []"
                    @blur="updateDeviceState('battery_serial_number', $event.target.value)"
                    :disabled="device.is_in_cart"
      />
    </div>

    <div v-if="(device_type === 'bike' || device_type === 'ebike')">
      <v-checkbox label="Wertgarantie-konformes Schloss? *"
                  :value="lockPriceConfirm"
                  :rules="[rules.required]"
                  hide-details="auto"
                  @change="updateDeviceState('lock_price_confirm', $event)"
                  :disabled="device.is_in_cart"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { isEmpty } from 'lodash'

export default {
  name: 'BikeEbikeExtras',
  props: {
    device: {},
    cart_index: {}
  },
  data: () => ({
    lockPriceConfirm: false,
    rules: {
      required: value => !!value || '* Pflichtfeld.'
    }
  }),
  computed: {
    ...mapGetters(['isAkkuschutz']),
    device_type() {
      return (!isEmpty(this.device.current_device_class_object)) ? this.device.current_device_class_object.type : ''
    }
  },
  methods: {
    ...mapMutations(['updateDevice']),
    async updateDeviceState(key, value) {
      if (value !== '' && this.device[key] !== value) {
        await this.updateDevice({
          index: this.cart_index,
          device_data: { [key]: value }
        })
      }
    }
  }
}
</script>
