<template>
  <v-col>
    <v-row align="center">
      <v-col cols="3">
        <h3>Geräteklasse suchen</h3>
      </v-col>
      <v-col>
        <h3>oder beliebte Geräteklasse auswählen:</h3>
      </v-col>
    </v-row>
    <v-row class="mb-8" align="start">
      <v-col cols="3">
        <treeselect :options="filteredDeviceClasses"
                    v-model="currentDeviceClassObject"
                    valueFormat="object"
                    :label="this.device_classes.name"
                    placeholder="Geräteklasse *"
                    no-results-text='Keine passende Geräteklasse gefunden'
                    noOptionsText="Keine Geräteklassen gefunden."
                    :disable-branch-nodes="true"
                    :normalizer="normalizer"
                    @input="onDeviceClassSelect"
                    ref="new_contract_device_class"
        />
      </v-col>
      <v-col>
        <v-row dense>
          <v-col v-for="device in popularDeviceClasses"
                 class="popular"
                 :key="device.id"
                 :class="{active: activeId === device.id}">
            <button class="fill-height d-flex flex-column align-center justify-center"
                    @click="onPopularDeviceSelect(device)">
              <img :src="'/_icons/'+device.icon" :alt="device.name" />
              <v-spacer />
              <span class="subtitle-2 pt-2"> {{ device.name }}</span>
            </button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>


<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { mapActions, mapGetters, mapState } from 'vuex'
import { getFilteredDeviceClasses } from '@/store/helpers'

export default {
  name: 'new-contract-device-class',
  components: { Treeselect },
  props: ['cart_index'],
  data: () => ({
    activeId: null,
    currentDeviceClassObject: null,
    popularDeviceClasses: [
      { name: 'Smartphone', icon: 'smartphone.svg', id: '1dfd4549-9bdc-4285-9047-e5088272dade' },
      { name: 'Fahrrad', icon: 'bike.svg', id: 'b4f0b0ec-5c95-4bc0-a7f5-00544a994a4b' },
      { name: 'E-Bike', icon: 'ebike.svg', id: 'ac8999a0-6e37-4488-8737-a566a068535b' },
      { name: 'Tablet', icon: 'tablet.svg', id: 'bb3a615d-e92f-4d24-a4cc-22f8a87fc544' },
      { name: 'Notebook', icon: 'notebook.svg', id: '5b863429-7b7f-4d34-9077-c2783966fa4d' },
      { name: 'TV', icon: 'tv.svg', id: '84f92c0d-72df-4efa-8b1b-60d5ba447da8' },
      { name: 'Waschmaschine', icon: 'waschmaschine.svg', id: '65b030ed-1c02-4b38-860f-0eba77fdc0ed' },
      { name: 'Hörgerät', icon: 'hoergeraet.svg', id: '3f34e9ef-d7b6-420d-b9e3-a5e3ab9f74d5' },
      { name: 'Smartwatch', icon: 'smartwatch.svg', id: 'e350e495-b7a2-47c9-a448-952f2fe4acf5' }
    ]
  }),
  computed: {
    ...mapState(['selected_devices', 'device_classes', 'allowed_device_classes']),
    ...mapGetters(['getDeviceClassIdByWinsureId']),
    device() {
      return this.selected_devices[this.cart_index]
    },
    filteredDeviceClasses() {
      return getFilteredDeviceClasses(this.device_classes, this.allowed_device_classes)
    }
  },
  methods: {
    ...mapActions(['getManufacturers', 'selectNewContractDevice']),
    normalizer(node) {
      return {
        label: node.name
      }
    },
    async onDeviceClassSelect(node) {
      const deviceClassId = this.getDeviceClassIdByWinsureId(node.winsure_id.toString())
      await this.selectNewContractDevice({ device_id: node.id, device_class_id: deviceClassId })
      this.scrollToProductSelection()
    },
    async onPopularDeviceSelect(device) {
      this.activeId = device.id
      await this.selectNewContractDevice({ device_id: device.id, device_class_id: device.id })
      this.$nextTick(() => {
        document.getElementById('product-selection').scrollIntoView({ behavior: 'smooth' })
      })
    },
    scrollToProductSelection() {
      this.$nextTick(() => {
        document.getElementById('product-selection').scrollIntoView({ behavior: 'smooth' })
      })
    }
  }
}
</script>
