<template>
  <v-text-field type="date"
                ref="purchase_date"
                label="Kaufdatum *"
                hide-details="auto"
                :value="purchase_date"
                :rules="rulesArray"
                @blur="updatePurchaseDate"
                :max="maxDate"
                :min="minDate"
                :disabled="device.is_in_cart"
  />
</template>
<script>
import { mapMutations } from 'vuex'
import moment from 'moment/moment'
import { isEmpty } from 'lodash'

export default {
  name: 'PurchaseDate',
  props: {
    device: {},
    cart_index: {}
  },
  data: () => ({
    rules: {
      required: value => !!value || '* Pflichtfeld.',
      purchase_date: value => {
        return (moment(value).diff(moment(), 'hours') < 0) || 'Kaufdatum darf nicht in der Zukunft liegen'
      },
      min_date: value => {
        return (moment(value).diff(moment().subtract(6, 'months'), 'hours') > 0) || 'Kaufdatum muss innerhalb der letzten 6 Monate liegen'
      }
    }

  }),
  computed: {
    deviceType() {
      return this.device?.current_device_class_object?.type || ''
    },
    rulesArray() {
      const rules = [this.rules.required, this.rules.purchase_date]

      if (this.deviceType === 'bike' || this.deviceType === 'ebike') {
        rules.push(this.rules.min_date)
      }
      return rules
    },
    minDate() {
      return (this.deviceType === 'bike' || this.deviceType === 'ebike')
          ? moment().subtract(6, 'months').toISOString().split('T')[0]
          : null
    },
    maxDate() {
      return new Date().toISOString().split('T')[0]
    },
    purchase_date() {
      return isEmpty(this.device.purchased_at) ? null : this.device.purchased_at.split(' ')[0]
    }
  },
  methods: {
    ...mapMutations(['updateDevice']),
    async updatePurchaseDate(e) {
      const value = e.target.value

      if (value !== '' && this.device.purchased_at !== value) {
        await this.updateDevice({
          index: this.cart_index,
          device_data: { purchased_at: value }
        })
      }
    }
  }
}
</script>
