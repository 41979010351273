import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { getInitialState } from './initialState'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['user', 'device_classes', 'employees'],
      storage: window.sessionStorage
    })
  ],
  state: getInitialState(),
  getters,
  actions,
  mutations
})
