export function getInitialState() {
  return {
    active_device_id: null,
    allowed_device_classes: [],
    cart: {},
    checkout_done: false,
    conclusion_chosen: false,
    contracts: [],
    current_device_id: null,
    current_device_class_id: null,
    current_email_template: null,
    current_employee: {},
    current_akp: null,
    customer_data: {},
    device_is_loading: false,
    email_templates: [],
    selected_product: null,
    devices: [],
    device_classes: [],
    email_data: {},
    employees: [],
    filtered_devices: [],
    has_valid_customer_data: false,
    has_voucher: false,
    insurance_contract: [],
    insurance_token: null,
    is_first_device: false,
    is_loading: false,
    is_new_contract: false,
    issues: [],
    product_offers: [],
    product_quote: [],
    promotions: [],
    reminder_email_data: {},
    selected_devices: [],
    settings: {
      direct_contract: true,
      prefill_mode: null,
      skip_to_summary: false,
      prevent_step_change: false,
      reminder_mails: false,
      with_attachments: false
    },
    show_mail_communication: false,
    status: { message: null, icon: 'information' },
    user: { engage_token: null }
  }
}
