<template>
  <v-row align="end">
    <v-col cols="8">
      <v-form lazy-validation v-model="isValid" ref="personalDataForm">
        <section>
          <v-row>
            <v-col className="pt-1">
              <h3>Persönliche Daten</h3>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-select
                  :items="this.salutationItems"
                  data-cy-select="salutation"
                  dense
                  label="Anrede"
                  hide-details
                  outlined
                  :value="customer.salutation"
                  @change="updateCustomerValues('salutation', $event)"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                  data-cy-input="firstname"
                  dense
                  label="Vorname"
                  hide-details
                  outlined
                  :value="customer.first_name"
                  @blur="updateCustomerValues('first_name', $event.target.value)"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                  data-cy-input="lastname"
                  dense
                  label="Nachname"
                  hide-details
                  outlined
                  :value="customer.last_name"
                  @blur="updateCustomerValues('last_name', $event.target.value)"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                  data-cy-input="birthdate"
                  type="date"
                  dense
                  label="Geburtsdatum"
                  hide-details
                  outlined
                  :value="birthdate"
                  @blur="updateCustomerValues('birthdate', $event.target.value)"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <v-text-field
                  data-cy-input="street"
                  dense
                  label="Straße"
                  hide-details
                  outlined
                  :value="customer.street"
                  @blur="updateCustomerValues('street', $event.target.value)"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                  data-cy-input="street-number"
                  dense
                  label="Hausnr."
                  hide-details
                  outlined
                  :value="customer.street_number"
                  @blur="updateCustomerValues('street_number', $event.target.value)"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                  data-cy-input="postalcode"
                  dense
                  type="number"
                  max="99999"
                  label="PLZ"
                  hide-details="auto"
                  outlined
                  :value="customer.postalcode"
                  @blur="updateCustomerValues('postalcode', $event.target.value)"
                  :rules="[rules.required, rules.plz]"
              />
            </v-col>
            <v-col cols="5">
              <v-text-field
                  data-cy-input="city"
                  dense
                  label="Ort"
                  hide-details
                  outlined
                  :value="customer.city"
                  @blur="updateCustomerValues('city', $event.target.value)"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field
                  data-cy-input="phone-private"
                  dense
                  label="Telefon (privat)"
                  hide-details
                  outlined
                  :value="customer.phone_private"
                  @blur="updateCustomerValues('phone_private', $event.target.value)"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                  data-cy-input="phone-mobile"
                  dense
                  label="Telefon (mobil)"
                  hide-details
                  outlined
                  :value="customer.mobile_phone_private"
                  @blur="
              updateCustomerValues('mobile_phone_private', $event.target.value)
            "
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                  data-cy-field="customernumber"
                  dense
                  readonly
                  label="Partnernummer"
                  hide-details
                  outlined
                  :value="customer.customer_number"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field
                  data-cy-input="email"
                  dense
                  label="E-Mail (privat)"
                  hide-details="auto"
                  outlined
                  :value="customer.email_private"
                  @blur="updateCustomerValues('email_private', $event.target.value)"
              />
            </v-col>
            <v-col cols="4" offset="4">
              <v-btn
                  data-cy-cta="change-customer-data"
                  v-if="dataHasChanged"
                  color="red"
                  block
                  class="mx-auto white--text"
                  @click="onSubmitCustomerData"
              >
                <v-icon left>mdi-cloud-upload</v-icon>
                Kundendaten ändern
              </v-btn>
              <confirm ref="confirm" />
            </v-col>
          </v-row>
        </section>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Confirm from '@/components/confirm'

export default {
  name: 'personal-data',
  components: { Confirm },
  data: () => ({
    salutationItems: ['Herr', 'Frau'],
    isValid: false,
    dataHasChanged: false,
    confirmSubmitCustomerData: false,
    rules: {
      required: (value) => !!value || '* Pflichtfeld.',
      plz: value => {
        return (value) ? value.length === 5 || 'Die PLZ muss fünfstellig sein.' : false
      }
    }
  }),
  computed: {
    ...mapState({
      customer: (state) => state.customer_data,
      birthdate: (state) => {
        if (
            state.customer_data.birthdate !== null &&
            state.customer_data.birthdate.includes(':')
        ) {
          return state.customer_data.birthdate.split(' ')[0]
        } else return state.customer_data.birthdate
      }
    })
  },
  mounted() {
    this.isValid = this.$refs.personalDataForm.validate()
  },
  methods: {
    ...mapMutations(['updateCustomer']),
    ...mapActions(['updateCustomerData']),
    updateCustomerValues(key, value) {
      this.updateCustomer({ [key]: value })
    },
    async onSubmitCustomerData() {
      if (
          await this.$refs.confirm.open(
              'Stammdatenänderung',
              `Wollen Sie wirklich die Änderungen an Winsure senden?`,
              { color: 'red' }
          )
      ) {
        await this.updateCustomerData()
        this.dataHasChanged = false
      }
    }
  },
  watch: {
    customer: {
      deep: true,
      handler() {
        this.dataHasChanged = true
      }
    }
  }
}
</script>
