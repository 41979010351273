<template>
  <v-col>
    <v-row align="center">
      <v-col cols="4">
        <h3>{{ selected_product.name }}</h3>
      </v-col>
      <v-col cols="2" class="text-right">
        <v-btn color="primary"
               class="mx-auto"
               v-if="!checkout_done"
               @click="restart"
        >
          <v-icon left>mdi-arrow-u-right-top</v-icon>
          Neu beginnen
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-select
            :value="current_employee"
            :items="employees"
            :item-text="getEmployeeLabel"
            @change="onEmployeeChange"
            dense
            label="Mitarbeiter:"
            outlined
            return-object
            hide-details
        />
      </v-col>
      <v-col cols="3">
        <v-select
            :value="current_akp"
            :items="current_employee.akp_numbers"
            :item-text="getAkpLabel"
            @change="onAkpChange"
            dense
            label="AKP-Nummer:"
            outlined
            return-object
            hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
        <h4>Geräteauswahl</h4>
      </v-col>
      <v-col cols="3">
        <h4>Zahlung</h4>
      </v-col>
    </v-row>

    <v-row id="devices" v-if="hasSelectedProduct">
      <v-col cols="3" v-for="(device, i) in selected_devices" :key="device.id" class="selected-device">
        <device :cart_index="i" />
      </v-col>
      <v-col cols="3" v-if="(is3for2 || cartHasActive3for2Promotion) && selected_devices.length < 3">
        <v-card class="fill-height d-flex flex-column">
          <v-card-title>Weiteres Gerät</v-card-title>
          <v-card-text>
            <p>Bitte überprüfen Sie die Daten des Geräts und nehmen evtl. Ergänzungen oder Korrekturen vor.</p>

            <p>Anschließend können Sie das Gerät in den Warenkorb legen und mit der Auswahl eines neuen Geräts
              beginnen.</p>
          </v-card-text>
          <v-spacer />
          <v-card-actions>
            <v-btn color="primary" text @click="addNewDevice" :disabled="!devicesAreInCart">
              <v-icon left>mdi-fan-plus</v-icon>
              Neues Gerät
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <payment v-if="hasProductQuote" />
      <confirm ref="confirm" />
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import Device from '@/components/device'
import Payment from '@/components/payment'
import Confirm from '@/components/confirm'

export default {
  name: 'contract',
  components: { Device, Payment, Confirm },
  computed: {
    ...mapState(['checkout_done', 'selected_product', 'employees', 'current_employee', 'current_akp',
      'selected_devices', 'product_quote', 'cart']),
    ...mapGetters(['is3for2', 'getDefaultEmployee', 'hasSelectedProduct',
      'hasSelectedDevices', 'hasProductQuote', 'cartHasActive3for2Promotion']),
    devicesAreInCart() {
      return this.selected_devices.every(device => device.is_in_cart)
    }
  },
  mounted() {
    this.setCurrentEmployee(this.getDefaultEmployee)
    this.setCurrentAkp(this.current_employee.akp_numbers[0])
  },
  methods: {
    ...mapMutations(['setCurrentEmployee', 'setCurrentAkp', 'addEmptyDevice', 'resetState']),
    addNewDevice() {
      this.addEmptyDevice()
    },
    getAkpLabel(item) {
      return `${ item.number } - ${ item.name }`
    },
    getEmployeeLabel(item) {
      return `${ item.first_name }  ${ item.last_name }`
    },
    onEmployeeChange(value) {
      this.setCurrentEmployee(value)
    },
    onAkpChange(value) {
      this.setCurrentAkp(value)
    },
    async restart() {
      if (await this.$refs.confirm.open('Neu beginnen',
          `Wollen Sie wirklich ihre Eingaben löschen und neu beginnen?`,
          { color: 'red', width: '500' })) {
        this.resetState()
        this.$nextTick(() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        })
      }
    }
  }
}
</script>
