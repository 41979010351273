<template>
  <section id="existing-devices" class="my-5">
    <v-row align="center">
      <v-col>
        <v-card elevation="1" class="pa-3" color="grey lighten-4">
          <span class="subtitle-2 pr-5">Filter:</span>
          <v-chip v-for="(filter, index) in filterItems"
                  :key="filter.label"
                  :color="filter.color"
                  small
                  label
                  class="mr-2"
                  @click="filterByState(index)"
                  :outlined="hasStateFilter(filter)"
                  :text-color="hasStateFilter(filter) ? '' : 'white'"
          >
            {{ filter.label }}
          </v-chip>
          <span class="px-3">|</span>
          <v-chip v-for="contract in contracts"
                  :key="contract.id"
                  color="primary"
                  small
                  label
                  class="mr-2"
                  @click="filterByContracts(contract.id)"
                  :outlined="hasContractFilter(contract.id)"
                  :text-color="hasContractFilter(contract.id) ? '' : 'white'"
          >
            {{ contract.product_name }}
          </v-chip>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="6">
        <h3>Geräteübersicht</h3>
      </v-col>
      <v-col cols="6">
        <h3>Geräte-Details</h3>
      </v-col>
    </v-row>

    <v-row align="start">
      <v-col cols="6">
        <v-row dense>
          <v-col cols="6" v-for="device in filtered_devices" :key="device.id">
            <existing-device :device="device"
                             :isSelected="active_device_id === device.id"
                             @set-active-device="setActiveDevice"
                             @select-device="handleDeviceAdd"
            />
          </v-col>
          <v-col v-if="filtered_devices.length === 0">
            <p>Keine Geräte zum anzeigen gefunden. Bitte ändern Sie ihre Filterkombination.</p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <existing-device-details v-if="active_device_id !== null" />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { isEmpty, isEqual, remove } from 'lodash'
import ExistingDevice from '@/components/existing-device'
import ExistingDeviceDetails from '@/components/existing-device-details'

export default {
  name: 'existing-devices',
  components: { ExistingDeviceDetails, ExistingDevice },
  data: () => ({
    filterItems: [
      { label: 'Aktiv', color: 'green', filter_key: 'status', value: 1 },
      { label: 'Inaktiv', color: 'red', filter_key: 'status', value: 3 },
      { label: 'Kündigung vorgemerkt', color: 'orange', filter_key: 'is_terminated', value: true },
      { label: 'TechManager', color: 'purple', filter_key: 'is_tech_manager', value: true },
      { label: 'BikeManager', color: 'lime', filter_key: 'is_bike_manager', value: true }
    ],
    activeFilters: []
  }),
  computed: {
    ...mapState(['filtered_devices', 'contracts', 'active_device_id', 'current_device_id', 'is_loading']),
    ...mapGetters(['getDeviceClassIdByWinsureId', 'cartHasActive3for2Promotion', 'hasSelectedDevices'])
  },
  methods: {
    ...mapMutations(['filterDevices', 'setActiveDeviceId', 'setCurrentDeviceId', 'addEmptyDevice', 'setIsFirstDevice']),
    ...mapActions(['selectDevice']),
    setActiveDevice(id) {
      this.setActiveDeviceId(id)
    },
    async handleDeviceAdd(device) {
      const deviceClassId = !isEmpty(device.hyve_device_class_id)
          ? device.hyve_device_class_id
          : this.getDeviceClassIdByWinsureId(device.device_class)
      await this.selectDevice({ device_id: device.id, device_class_id: deviceClassId })
      this.scrollToContract()
    },
    filterByState(index) {
      const currentFilter = this.filterItems[index]

      if (this.activeFilters.some(item => isEqual(item, currentFilter))) {
        this.activeFilters = this.activeFilters.filter(item => !isEqual(item, currentFilter))
      } else {
        this.activeFilters.push(currentFilter)
      }

      this.filterDevices(this.activeFilters)
    },
    filterByContracts(id) {
      const devicesToFilter = this.contracts.find(contract => contract.id === id).devices
      const found = this.activeFilters.some(activeFilter => devicesToFilter.includes(activeFilter.value))

      if (found) {
        devicesToFilter.forEach(deviceId => {
          remove(this.activeFilters, (activeFilter => activeFilter.value === deviceId))
        })
      } else {
        devicesToFilter.forEach(deviceId => {
          this.activeFilters.push({
            filter_key: 'id',
            value: deviceId
          })
        })
      }
      this.filterDevices(this.activeFilters)
    },
    hasStateFilter(filter) {
      return this.activeFilters.some(activeFilter => isEqual(activeFilter, filter))
    },
    hasContractFilter(id) {
      const devicesToFilter = this.contracts.find(contract => contract.id === id).devices
      return this.activeFilters.some(activeFilter => devicesToFilter.includes(activeFilter.value))
    },
    scrollToContract() {
      const productSelection = document.getElementById('product-selection')
      if (productSelection !== null) {
        this.$nextTick(() => {
          productSelection.scrollIntoView({ behavior: 'smooth' })
        })
      }
    },
    scrollToNewContractProductSelection() {
      this.$nextTick(() => {
        document.getElementById('new-contract-product-selection').scrollIntoView({ behavior: 'smooth' })
      })
    }
  }
}
</script>


