<template>
  <v-slide-y-transition leave-absolute>
    <router-view />
  </v-slide-y-transition>
</template>

<script>
export default {
  name: 'App'
}
</script>
