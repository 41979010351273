<template>
  <v-card class="mx-auto device"
          :class="[{selected: isSelected}, {not_insurable: !device.is_insurable},getCardColor(device)]"
          @click.native="setActiveDevice(device.id)"
  >
    <v-card-text class="pa-3 d-flex flex-column justify-space-between" style="min-height: 150px">
      <v-row dense>
        <v-col>
          <div class="mb-0"><strong>{{ device.product_name }}</strong></div>
          <div class="mb-0">
            <p>{{ device.name }}</p>
            <p>{{ device.manufacturer_name }} {{ device.type }}</p>
          </div>
        </v-col>
        <v-col cols="1" class="text-right" v-if="hasComponents(device)">
          <v-icon small class="component_icon">mdi-puzzle</v-icon>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="9">
          <p>
            <v-icon small class="mr-2">mdi-calendar-month</v-icon>
            {{ formatDate(device.purchased_at) }}
          </p>
          <p>
            <v-icon small class="mr-2">mdi-currency-eur</v-icon>
            {{ formatPrice(device.purchase_price) }}
          </p>
        </v-col>

        <v-col cols="3" class="text-right" align-self="end" v-if="device.is_insurable && selected_devices.length < 3">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="handleDevice(device)" v-bind="attrs" v-on="on" :loading="device.is_loading">
                <v-icon>mdi-tray-arrow-down</v-icon>
              </v-btn>
            </template>
            <span>{{ device.name }} übernehmen</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { has } from 'lodash'
import { mapState } from 'vuex'

export default {
  name: 'existing-device',
  props: ['device', 'isSelected'],
  computed: {
    ...mapState(['selected_devices'])
  },
  methods: {
    setActiveDevice(id) {
      this.$emit('set-active-device', id)
    },
    handleDevice(device) {
      this.$emit('select-device', device)
    },
    hasComponents(device) {
      return has(device, 'components')
    },
    getCardColor(device) {
      switch (device.status) {
        case 1:
          return (device.is_terminated) ? 'terminated' : 'active'
        case 3:
          return 'inactive'
        case 99:
          if (device.is_tech_manager) return 'tech-manager'
          else return 'bike-manager'
        default:
          return 'unknown'
      }
    },
    formatPrice(value) {
      if (value !== null) {
        const price = value.includes(',') ? value.replace(',', '.') : value
        return new Intl.NumberFormat('de-DE',
            { style: 'currency', currency: 'EUR' }
        ).format(price)
      }
    },
    formatDate(date) {
      return this.moment(date).format('DD.MM.YYYY')
    }
  }
}
</script>
