<template>
  <v-row align="start">
    <v-col cols="8">
      <v-row align="center" dense class="mt-2">
        <v-col cols="5">
          <v-text-field v-model="customer_number"
                        @focus="onCustomerNumberFocus"
                        @keypress.enter="onSubmit"
                        dense
                        type="number"
                        outlined
                        label="Partnernummer"
                        tabindex="1"
                        hide-details
                        data-cy-input="customernumber"
          />
        </v-col>
        <v-col cols="2">
          <div class="text-center">oder</div>
        </v-col>
        <v-col cols="5">
          <v-text-field v-model="contract_number"
                        @focus="onContractNumberFocus"
                        @keypress.enter="onSubmit"
                        dense
                        type="number"
                        outlined
                        label="Vertragsnummer"
                        tabindex="2"
                        hide-details
                        data-cy-input="contractnumber"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn :loading="loading"
                 :disabled="loading"
                 color="primary"
                 data-cy-cta="loading-data"
                 @click="onSubmit"
                 @keypress.enter="onSubmit"
          >
            <v-icon left>mdi-cloud-download</v-icon>
            Daten laden
          </v-btn>

          <v-btn color="primary" @click="onButtonClick" class="ml-5">
            <v-icon left>mdi-newspaper-plus</v-icon>
            Neuvertrag
          </v-btn>
        </v-col>
      </v-row>

    </v-col>
  </v-row>
</template>

<script>
// (Test-Partnernr: 10034330 | 12617428 | Test-Vertragsnummern: 51244362 / 55014914 / 58269947) 13292448
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'customer-number',
  data() {
    return {
      customer_number: null,
      contract_number: null,
      loading: false
    }
  },
  methods: {
    ...mapMutations(['setIsNewContract', 'resetState']),
    ...mapActions(['getCustomerData', 'getComponentClasses', 'pruneCart']),
    async onButtonClick() {
      this.resetState()
      this.setIsNewContract(true)
      await this.pruneCart()
      await this.getComponentClasses()
    },
    onCustomerNumberFocus() {
      this.contract_number = null
    },
    onContractNumberFocus() {
      this.customer_number = null
    },
    async onSubmit() {
      let payload = {}
      const formData = new FormData()

      if (this.customer_number !== null || this.contract_number !== null) {
        this.resetState()
        this.setIsNewContract(false)
        this.loading = true
        await this.pruneCart()

        if (this.customer_number !== null) {
          payload = { data: this.customer_number, by: 'customer_number' }
        } else if (this.contract_number !== null) {
          formData.append('contract_number', this.contract_number)
          formData.append('include', 'contracts.devices')
          payload = { data: formData, by: 'contract_number' }
        }

        await this.getComponentClasses()
        await this.getCustomerData(payload)

        this.loading = false
        this.contract_number = null
        this.customer_number = null
      }
    }
  }
}
</script>
