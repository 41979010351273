<template>
  <v-text-field :label="(device_type === 'bike' || device_type === 'ebike' ) ? 'Rahmennummer *' : 'Seriennummer'"
                :value="device.number"
                @blur="updateDeviceNumber($event.target.value)"
                :disabled="device.is_in_cart"
                :rules="(device_type === 'bike' || device_type === 'ebike') ? [rules.required, rules.serial] : []"
                hide-details="auto"
  />
</template>
<script>
import { isEmpty } from 'lodash'
import { mapMutations } from 'vuex'

export default {
  name: 'SerialNumber',
  props: {
    device: {},
    cart_index: {}
  },
  data: () => ({
    rules: {
      required: value => !!value || '* Pflichtfeld.',
      serial: value => !!value && value.length >= 6 || 'Min. 6 Stellen'
    }
  }),
  computed: {
    device_type() {
      return (!isEmpty(this.device.current_device_class_object)) ? this.device.current_device_class_object.type : ''
    }
  },
  methods: {
    ...mapMutations(['updateDevice']),
    async updateDeviceNumber(value) {
      if (value !== '' && this.device.number !== value) {
        await this.updateDevice({
          index: this.cart_index,
          device_data: { number: value }
        })
      }
    }
  }
}
</script>
