<template>
  <v-row class="user">
    <v-spacer/>
    <v-col cols="10" md="12" lg="10" v-if="userItems" @click="resetStatus()">
      <v-card class="px-8 py-5" elevation="3">
        <v-row>
          <v-col class="col-main">
            <v-row justify="space-between" dense class="position-relative">
              <v-col cols="2" class="mb-5">
                <h3>Benutzer</h3>
              </v-col>
              <v-col cols="10">
                <v-btn color="primary"
                       @click="addUser"
                >
                  <v-icon left>mdi-account-plus</v-icon>
                  Neu
                </v-btn>
              </v-col>
              <v-text-field dense label="Nachname" hide-details outlined @input="filterLastName = $event"
                            class="filter-input"/>
              <v-col>
                <template>
                  <v-data-table
                      v-model="selectedRow"
                      :headers="headers"
                      :items="filteredUserItems"
                      :sort-by="['last_name']"
                      :items-per-page="15"
                      class="elevation-2"
                      item-key="user.email"
                      :single-select="true"
                      fixed-header
                      ref="dataTable"
                      @click:row="selectUser"
                  ></v-data-table>
                </template>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="col-separator"></v-col>
          <v-col class="col-main">
            <v-row justify="space-between" dense>
              <v-col cols="12" class="mb-5">
                <h3>Benutzerdaten</h3>
              </v-col>
              <v-col cols="12">
                <v-row justify="space-between">
                  <v-col cols="3">
                    <v-select
                        dense
                        :items="salutationItems"
                        v-model="userItem.salutation"
                        label="Anrede"
                        hide-details
                        tabindex="1"
                        outlined
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field dense label="Vorname" tabindex="2" hide-details outlined
                                  v-model="userItem.first_name"/>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field dense label="Nachname" tabindex="3" hide-details outlined
                                  v-model="userItem.last_name"/>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field dense label="E-Mail" tabindex="4" hide-details outlined
                                  v-model="userItem.user.email"/>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field dense label="Passwort" tabindex="5" hide-details outlined
                                  v-model="userItem.user.password"
                                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                  @click:append="() => (showPassword = !showPassword)"
                                  :type="showPassword ? 'text' : 'password'"/>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                        dense
                        :items="typeItems"
                        item-text="displayValue"
                        item-value="value"
                        v-model="userItem.type"
                        label="Mitarbeitertyp"
                        hide-details
                        tabindex="1"
                        outlined
                    />
                  </v-col>
                  <v-col cols="8">
                    <v-switch
                        v-model="userItem.user.is_admin"
                        :disabled="currentUserId === userItem.id"
                        label="Kann Benutzer anlegen / bearbeiten"
                        hide-details
                        class="mt-1"
                    />
                  </v-col>
                </v-row>
                <v-row justify="space-between" dense class="mt-15">
                  <v-col cols="2" class="mb-5">
                    <h3>AKPs</h3>
                  </v-col>
                  <v-col>
                    <v-btn color="primary"
                           @click="addAkp"
                    >
                      <v-icon left>mdi-plus-circle-outline</v-icon>
                      Hinzufügen
                    </v-btn>
                  </v-col>

                  <v-col cols="12">
                    <v-row v-for="(akp, i) in userItem.partners" :key="i">
                      <v-col class="akp-field">
                        <v-text-field dense label="Name" :tabindex="6 + i" hide-details outlined
                                      v-model="userItem.partners[i].name"/>
                      </v-col>
                      <v-col class="akp-field">
                        <v-text-field dense label="Nummer" :tabindex="7 + i" hide-details outlined type="number"
                                      v-model="userItem.partners[i].akp_number"/>
                      </v-col>
                      <v-col class="akp-field">
                        <v-text-field dense label="Voucher" :tabindex="7 + i" hide-details outlined
                                      v-model="userItem.partners[i].voucher"/>
                      </v-col>
                      <v-col class="akp-field-small">
                        <v-icon @click="removeAkp(i)">mdi-minus-circle-outline</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="mt-10">
                  <v-col>
                    <v-btn color="primary"
                           @click="saveUser"
                           :disabled="!saveButtonEnabled"
                    >
                      <v-icon left>mdi-content-save</v-icon>
                      Speichern
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-spacer/>

    <v-overlay :opacity=".8" :value="show_loading_spinner">
      <v-progress-circular indeterminate size="128">
        Bitte warten...
      </v-progress-circular>
    </v-overlay>
  </v-row>
</template>

<script>
import engage from "../api/engage";
import store from "../store";

export default {
  name: 'User',
  data: () => ({
    headers: [
      {text: 'Vorname', value: 'first_name'},
      {text: 'Nachname', value: 'last_name'},
      {text: 'E-Mail', value: 'user.email'}
    ],
    defaultUserItem: {
      id: null,
      salutation: null,
      first_name: null,
      last_name: null,
      user: {
        id: null,
        name: null,
        email: null,
        password: null,
        is_admin: null,
      },
      partners: [
        {
          id: null,
          name: null,
          akp_number: null,
          voucher: null
        }
      ]
    },
    userItem: null,
    userItems: null,
    filterLastName: null,
    selectedRow: [],
    showPassword: false,
    salutationItems: ['Herr', 'Frau'],
    typeItems: [{ displayValue: 'WERTGARANTIE Mitarbeiter', value: 'intern' },{ displayValue: 'Externer Mitarbeiter', value: 'extern' }],
    show_loading_spinner: false
  }),

  mounted() {
    this.show_loading_spinner = true;
    this.getEmployees();
  },

  computed: {
    filteredUserItems() {
      let userItems = this.userItems;
      if (this.filterLastName) {
        userItems = userItems.filter(item => item.last_name.toLowerCase().indexOf(this.filterLastName.toLowerCase()) > -1)
      }
      return userItems;
    },

    saveButtonEnabled() {
      return this.userItem.salutation && this.userItem.first_name && this.userItem.last_name && this.userItem.user.email && this.userItem.type && (this.userItem.id || this.userItem.user.password) && this.userItem.partners.length > 0 && this.userItem.partners[0].name && this.userItem.partners[0].akp_number;
    },

    currentUserId() {
      return store.state.user.id;
    }
  },

  methods: {
    selectUser(item, row) {
      row.select(true);
      this.userItem = JSON.parse(JSON.stringify(item));
      this.showPassword = false;
    },

    addUser() {
      this.selectedRow = [];
      this.userItem = JSON.parse(JSON.stringify(this.defaultUserItem));
    },

    saveUser() {
      this.show_loading_spinner = true;
      let partners = [];
      this.userItem.partners.forEach(partner => {
        if (partner.akp_number && partner.name) {
          partner.voucher = partner.voucher ? partner.voucher : null;
          partners.push(partner);
        }
      })
      this.userItem.partners = partners;
      this.userItem.user.name = this.userItem.first_name + ' ' + this.userItem.last_name;
      this.userItem.user.is_admin = this.userItem.user.is_admin ? 1 : 0;

      if (this.userItem.id) {
        this.updateEmployee();
      } else {
        this.addEmployee();
      }
    },

    async getEmployees() {
      const response = await engage.getEmployees();
      this.userItem = JSON.parse(JSON.stringify(this.defaultUserItem));
      this.userItems = response.data.data;
      this.show_loading_spinner = false;
    },

    async updateEmployee() {
      const response = await engage.updateEmployee(this.userItem, this.userItem.id);

      if (response.status === 200) {
        const response2 = await engage.getEmployees();
        if (response2.status === 200) {
          this.userItems = response2.data.data;
          this.show_loading_spinner = false;
          store.commit('setStatus', {message: 'Speichern erfolgreich', icon: 'check'});
        }
      } else {
        this.show_loading_spinner = false;
        store.commit('setStatus', {message: response.response.data.errors['user.email'][0], icon: 'error'});
      }
    },

    async addEmployee() {
      const response = await engage.addEmployee(this.userItem);

      if (response.status === 200) {
        const response2 = await engage.getEmployees();

        if (response2.status === 200) {
          this.userItems = response2.data.data;
          this.userItem = JSON.parse(JSON.stringify(this.defaultUserItem));
          this.show_loading_spinner = false;
          store.commit('setStatus', {message: 'Speichern erfolgreich', icon: 'check'});
        }
      } else {
        this.show_loading_spinner = false;
        store.commit('setStatus', {message: response.response.data.errors['user.email'][0], icon: 'error'});
      }
    },

    addAkp() {
      this.userItem.partners.push({name: null, akp_number: null, id: null});
    },

    removeAkp(index) {
      this.userItem.partners.splice(index, 1);
    },

    resetStatus() {
      store.commit('setStatus', {message: null, icon: 'information'});
    }
  }
}
</script>

<style lang="scss">
.user {
  .col-main {
    flex: 0 0 48%;
    max-width: 48%;
  }

  .col-separator {
    flex: 0 0 4%;
    max-width: 4%;
  }

  .v-data-table {
    tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
      background: #bbdefb !important;
      cursor: pointer;
    }

    tr:nth-of-type(odd) {
      background-color: #F4FAFD;
    }

    tr.v-data-table__selected {
      background: #64b5f6 !important;
    }

    th {
      font-size: 11px !important;
    }

    td {
      font-size: 12px !important;
    }
  }

  .position-relative {
    position: relative;
  }

  .filter-input {
    position: absolute;
    right: 5px;
    top: 7px;

    &.v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot {
      min-height: 30px;
      font-size: 12px;

      label {
        top: 5px;
        font-size: 12px;
      }

      input {
        max-height: 30px;
      }
    }
  }

  .akp-field {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .akp-field-small {
    flex: 0 0 10%;
    max-width: 10%;
    line-height: 36px;
    text-align: right;

    button {
      color: #F44336;
    }
  }

  .add-akp {
    color: #1976d2;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
  }

  .mb-5 {
    line-height: 36px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
