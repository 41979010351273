<template>
  <v-text-field label="Kaufpreis *"
                hide-details="auto"
                :value="device.purchase_price"
                append-icon="mdi-currency-eur"
                :rules="rulesArray"
                @blur="onPriceChange"
                :disabled="device.is_in_cart"
                max="10000"
                ref="purchasePrice"
  />
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { isEmpty } from 'lodash'
import moment from 'moment'

export default {
  name: 'PurchasePrice',
  props: {
    device: {},
    cart_index: {}
  },
  data: () => ({
    rules: {
      required: value => !!value || '* Pflichtfeld.',
      priceMin: value => (value !== null && parseFloat(value) >= 1000) || 'Minimaler Kaufpreis: 1.000,- €',
      priceMax: value => (value !== null && parseFloat(value) <= 10000) || 'Maximaler Kaufpreis: 10.000,- €',
      priceBikeMax: value => (value !== null && parseFloat(value) <= 15000) || 'Maximaler Kaufpreis: 15.000,- €',
      noComma: value => {
        if (!value) return true
        return !value.includes(',') || 'Bitte verwenden Sie einen Punkt als Dezimaltrennzeichen.'
      },
      max2Decimals: value => {
        if (!value) return true
        return !value.includes('.') || value.split('.')[1].length <= 2 || 'Maximal 2 Nachkommastellen.'
      },
      onlyNumbers: value => {
        if (!value) return true
        return !isNaN(value) || 'Bitte nur Zahlen eingeben.'
      }
    }

  }),
  computed: {
    ...mapGetters(['isInCart']),
    rulesArray() {
      const deviceType = this.device?.current_device_class_object?.type || ''
      const rules = [this.rules.required, this.rules.noComma, this.rules.max2Decimals, this.rules.onlyNumbers]

      if (deviceType === 'bike' || deviceType === 'ebike') {
        rules.push(this.rules.priceBikeMax)
      } else {
        rules.push(this.rules.priceMax)
      }
      if (deviceType === 'ebike') {
        rules.push(this.rules.priceMin)
      }
      return rules
    }
  },
  methods: {
    ...mapActions(['addNewContractDevice', 'updateCartProduct']),
    ...mapMutations(['updateDevice']),
    async onPriceChange(e) {
      // Safari Workaround
      if (isEmpty(this.device.purchased_at)) {
        await this.updateDevice({
          index: this.cart_index,
          device_data: { purchased_at: moment().format('YYYY-MM-DD') }
        })
      }
      this.priceIsUpdating = true
      const purchase_date = this.device.purchased_at
      const purchase_price = e.target.value

      if (purchase_date !== null && purchase_price !== '' && purchase_price !== this.device.purchase_price) {
        await this.updateDevice({
          index: this.cart_index,
          device_data: { purchase_price: purchase_price }
        })

        if (this.$refs.purchasePrice.valid) {
          if (this.isInCart(this.cart_index)) {
            await this.updateCartProduct(this.cart_index)
          } else {
            await this.addNewContractDevice(this.cart_index)
          }
        }
      }
      this.priceIsUpdating = false
    }
  }
}
</script>
