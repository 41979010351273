<template>
  <v-col cols="3" :offset="colOffset">

    <v-card elevation="1" class="fill-height d-flex flex-column">
      <v-card-text class="px-5 py-3">
        <v-form ref="paymentForm" lazy-validation v-model="formIsValid">
          <h4 class="mb-3">Gesamtpreis:</h4>
          <div class="mb-8">
            <span class="price text--primary">{{ price }}</span>
            <span class="caption pl-2">{{ intervalText }}</span>
            <p class="caption mb-0">inkl. {{ tax }} Vers.-St.</p>
            <p v-if="hasInstantProtection" class="caption">zzgl. einmalig 3,50 € Sofortschutz</p>
          </div>

          <v-select
              :items="payment_intervals"
              v-model="currentInterval"
              item-text="name"
              label="Gewünschtes Zahlungsintervall"
              :loading="intervalIsLoading"
              hide-details
              return-object
              @change="handlePaymentIntervalChange"
              :disabled="this.currentDiscount.id !== null || this.payment_intervals.length === 1"
          />

          <v-select
              v-if="!this.cartHasActive3for2Promotion"
              class="mt-2"
              :items="availableDiscounts"
              :loading="discountIsLoading"
              v-model="currentDiscount"
              item-text="title"
              label="Vorauszahlung"
              hide-details
              return-object
              @change="onDiscountChange"
              :disabled="this.availableDiscounts.length === 1"
          />

          <v-select
              class="mt-2"
              :items="paymentMethods"
              v-model="currentPaymentMethod"
              label="Zahlart"
              hide-details
              @change="onPaymentMethodChange"
          />

          <v-text-field
              v-if="currentPaymentMethod === 'direct_debit'"
              ref="iban"
              label="IBAN"
              hide-details="auto"
              v-model="iban"
              @blur="onIbanChange"
              @keydown.enter="onIbanChange"
              :loading="ibanIsLoading"
              :rules="[ibanRules.required, ibanRules.valid]"
          />

          <v-checkbox
              :disabled="current_akp.voucher === null"
              v-model="coupon"
              label="beitragsfreier Monat?"
              @change="onCouponChange($event)"
              hide-details
          />
          <span v-if="current_akp.voucher === null" class="caption">Nur auswählbar wenn Ihre AKP-Nummer einen gültigen Gutscheincode hinterlegt hat.</span>
        </v-form>
      </v-card-text>
      <v-spacer />
      <v-card-actions class="flex-column">
        <v-btn color="primary" text block :disabled="!dataIsValid || conclusion_chosen" @click="onDirectConclusion">
          <v-icon left>mdi-arrow-down</v-icon>
          Direktabschluss
        </v-btn>
        <v-btn color="primary"
               v-if="current_employee.type === 'intern'"
               text
               block
               :disabled="!dataIsValid || conclusion_chosen"
               @click="onMailCommunication"
        >
          <v-icon left>mdi-email</v-icon>
          E-Mail-Kommunikation
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirm ref="confirm" />
  </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { has, isEmpty } from 'lodash'
import { getPaymentIntervals, isValidIBANNumber } from '@/store/helpers'
import Confirm from '@/components/confirm'
import insurance from '@/api/insurance'

export default {
  name: 'payment',
  components: { Confirm },
  data: () => ({
    coupon: false,
    confirmDirectConclusion: false,
    currentInterval: {},
    discountIsLoading: false,
    formIsValid: false,
    intervalIsLoading: false,
    currentDiscount: { id: null, title: 'Keine' },
    paymentMethods: [
      { text: 'SEPA Lastschrift', value: 'direct_debit' },
      { text: 'Überweisung', value: 'bank_transfer' }
    ],
    currentPaymentMethod: 'direct_debit',
    iban: null,
    ibanIsLoading: false,
    ibanRules: {
      required: value => !!value || '* Pflichtfeld.',
      valid: false
    }
  }),
  computed: {
    ...mapState(['cart', 'product_quote', 'conclusion_chosen', 'customer_data', 'current_employee', 'current_akp', 'selected_devices']),
    ...mapGetters(['cartHasActive3for2Promotion', 'cartHasActiveDiscount', 'conclusionAllowed', 'getSelectedDevicesCount', 'is3for2']),
    availableDiscounts() {
      let discounts = this.product_quote[0].available_discounts
      discounts.unshift({ id: null, title: 'Keine' })
      return discounts
    },
    colOffset() {
      if (this.is3for2) {
        return this.getSelectedDevicesCount === 1 ? 3 : 0
      } else {
        return 6
      }
    },
    dataIsValid() {
      return this.conclusionAllowed && (this.currentPaymentMethod === 'bank_transfer' || isValidIBANNumber(this.iban))
    },
    deviceType() {
      return (!isEmpty(this.selected_devices[0].current_device_class_object)) ? this.selected_devices[0].current_device_class_object.type : ''
    },
    hasInstantProtection() {
      return has(this.cart, 'totals.single') && (this.deviceType === 'bike' || this.deviceType === 'ebike')
    },
    intervalText() {
      if (has(this.cart.totals, 'savings')) {
        return 'einmalig'
      } else return (this.currentInterval.value === 1) ? 'pro Monat' : `alle ${ this.currentInterval.value } Monate`
    },
    payment_intervals() {
      return getPaymentIntervals(this.product_quote[0].selection_options[0])
    },
    price() {
      if (!isEmpty(this.cart.totals)) {
        if (has(this.cart.totals, 'annually')) {
          return this.formatPrice(this.cart.totals.annually)
        } else if (has(this.cart.totals, 'monthly')) {
          return this.formatPrice(this.cart.totals.monthly * this.currentInterval.value)
        } else {
          return this.formatPrice(this.cart.totals.single)
        }
      } else return 0
    },
    tax() {
      if (!isEmpty(this.cart.totals)) {
        if (has(this.cart.totals, 'annually_tax')) {
          return this.formatPrice(this.cart.totals.annually_tax)
        } else if (has(this.cart.totals, 'monthly_tax')) {
          return this.formatPrice(this.cart.totals.monthly_tax * this.currentInterval.value)
        } else {
          return this.formatPrice(this.cart.totals.single_tax)
        }
      } else return 0
    }
  },
  async mounted() {
    this.currentInterval = this.payment_intervals[0]
    await this.updatePaymentInterval(this.currentInterval.value)

    this.iban = this.customer_data.payment_iban

    if (this.iban !== null && await insurance.validateIban(this.iban)) {
      this.ibanRules.valid = true
      this.updateCustomer({ debit_mandate_accepted: 1 })
    }

    this.$refs.paymentForm.validate()
  },
  methods: {
    ...mapActions(['updatePaymentInterval', 'addCartProductDiscount', 'deleteCartProductDiscount',
      'getEmailTemplates', 'checkoutEngage', 'checkoutInsurance', 'addCartCoupon', 'deleteCartCoupon']),
    ...mapMutations(['updateCustomer', 'loadingStart', 'loadingStop', 'setConclucionChosen']),
    formatPrice(price) {
      return new Intl.NumberFormat('de-DE',
          { style: 'currency', currency: 'EUR' }
      ).format(price)
    },
    async handlePaymentIntervalChange(option) {
      this.intervalIsLoading = true
      await this.updatePaymentInterval(option.value)
      this.currentInterval = option
      this.intervalIsLoading = false
    },
    async onCouponChange(value) {
      if (value) {
        await this.addCartCoupon()
      } else {
        await this.deleteCartCoupon()
      }
    },
    async onDiscountChange(option) {
      this.discountIsLoading = true
      const payload = { cart_index: 0, discount_id: option.id }

      this.cartHasActiveDiscount
          ? await this.deleteCartProductDiscount(payload)
          : await this.addCartProductDiscount(payload)

      this.currentDiscount = option

      this.discountIsLoading = false
    },
    async onDirectConclusion() {
      if (await this.$refs.confirm.open('Direktabschluss',
          `Wollen Sie wirklich den Vertrag direkt abschließen?`,
          { color: 'red' })) {
        this.loadingStart()

        if (await this.checkoutInsurance()) {
          this.setConclucionChosen(true)
          await this.checkoutEngage('direct')
        }
        this.loadingStop()
      }
    },
    async onIbanChange(e) {
      this.ibanIsLoading = true
      if (await insurance.validateIban(e.target.value)) {
        this.ibanRules.valid = true
        this.updateCustomer({ payment_iban: e.target.value, debit_mandate_accepted: 1 })
      } else {
        this.ibanRules.valid = 'Ungültige IBAN'
      }
      this.ibanIsLoading = false
    },
    async onMailCommunication() {
      await this.getEmailTemplates()
      this.$nextTick(() => {
        document.getElementById('mail_communication').scrollIntoView({ behavior: 'smooth' })
      })
    },
    onPaymentMethodChange(option) {
      this.currentPaymentMethod = option
      this.updateCustomer({ payment_method: option })
      this.updateCustomer({ debit_mandate_accepted: true })
    }
  }
}
</script>
