import Vue from 'vue'
import { getDeviceClassObjectById } from '@/store/helpers'
import { isEmpty, omit } from 'lodash'
import { getInitialState } from '@/store/initialState'

const mutations = {
  addEmptyDevice(state) {
    if (isEmpty(state.selected_devices)) {
      Vue.set(state.selected_devices, 0, { is_new_device: true })
    } else {
      state.current_device_id = null
      state.current_device_class_id = null
      Vue.set(state.selected_devices, state.selected_devices.length, { is_new_device: true })
    }
  },

  addSelectedComponent: (state, payload) => {
    const current = state.selected_devices[payload.cart_index].selected_components
    current.push(payload)
    Vue.set(state.selected_devices[payload.cart_index], 'selected_components', current)
  },

  addSelectedDevice(state, device) {
    const deviceWithPremiums = {
      ...device,
      current_device_class_object: getDeviceClassObjectById(state.device_classes, state.current_device_class_id),
      current_manufacturer_object: null,
      is_new_device: false,
      is_in_cart: false,
      manufacturers: [],
      premiums: state.product_quote[0].premiums,
      selected_components: []
    }

    if (!isEmpty(state.cart.promotions)) {
      Vue.set(state, 'selected_devices', [...state.selected_devices, deviceWithPremiums])
    } else {
      Vue.set(state.selected_devices, 0, deviceWithPremiums)
    }
  },

  deleteSelectedComponent(state, payload) {
    const current = state.selected_devices[payload.cart_index].selected_components
    const deleteIndex = current.findIndex(el => el.cart_component_id === payload.cart_component_id)
    current.splice(deleteIndex, 1)
    Vue.set(state.selected_devices[payload.cart_index], 'selected_components', current)
  },

  deviceLoadingStart: (state, id) => {
    const index = state.filtered_devices.findIndex(device => device.id === id)
    state.filtered_devices[index].is_loading = true
    state.device_is_loading = true
  },

  deviceLoadingStop: (state, id) => {
    const index = state.filtered_devices.findIndex(device => device.id === id)
    state.filtered_devices[index].is_loading = false
    state.device_is_loading = false
  },

  filterDevices(state, currentFilter) {
    Vue.set(state, 'filtered_devices', state.devices.filter(device => !currentFilter.find(filter => device[filter.filter_key] === filter.value)))
  },

  filterDevicesByDeviceClass(state) {
    state.filtered_devices = state.filtered_devices.map(device => {
      if (!state.allowed_device_classes.includes(parseInt(device.device_class)) || state.current_device_id === device.id) {
        device.is_insurable = false
      }

      return device
    })
  },

  loadingStart: state => {
    state.is_loading = true
  },

  loadingStop: state => {
    state.is_loading = false
  },

  logout(state) {
    Object.assign(state.user, { token: null })
  },

  removeSelectedDevice(state, index) {
    const devices = state.selected_devices
    const filterIndex = state.filtered_devices.findIndex(device => device.id === state.selected_devices[index].id)
    devices.splice(index, 1)
    state.selected_devices = devices

    if (!isEmpty(state.filtered_devices[filterIndex])) {
      state.filtered_devices[filterIndex].is_insurable = true
    }
  },

  resetDevice: state => {
    state.cart = {}
    state.current_device_class_id = null
    state.current_device_id = null
    state.product_offers = []
    state.product_quote = []
    state.promotions = []
    state.selected_devices = []
    state.selected_product = null
    state.show_mail_communication = false
  },

  resetState: state => {
    Object.assign(state, omit(getInitialState(),
      'user',
      'device_classes',
      'employees',
      'current_employee',
      'current_akp',
      'insurance_token')
    )
  },

  setAccessToken: (state, access_token) => {
    state.user.engage_token = access_token
  },

  setActiveDeviceId(state, device_id) {
    state.active_device_id = device_id
  },

  setAllowedDeviceClasses(state, allowed_device_classes) {
    state.allowed_device_classes = allowed_device_classes
  },

  setCheckoutDone(state, value) {
    state.checkout_done = value
  },

  setConclucionChosen(state, value) {
    state.conclusion_chosen = value
  },

  setCurrentDeviceId(state, device_id) {
    state.current_device_id = device_id
  },

  setCurrentDeviceClassId(state, device_class_id) {
    state.current_device_class_id = device_class_id
  },

  setCart(state, cart) {
    Vue.set(state, 'cart', { ...state.cart, ...cart })
  },

  setCommunicationSettings(state, settings) {
    state.settings = { ...state.settings, ...settings }
  },

  setCurrentAkp: (state, akp) => {
    state.current_akp = akp
  },

  setCurrentEmployee: (state, employee) => {
    state.current_employee = employee
  },

  setCustomerData(state, customerData) {
    Object.assign(state, { ...customerData })
  },

  setDeviceClasses: (state, device_classes) => {
    state.device_classes = device_classes
  },

  setDeviceIsInCart(state, data) {
    Vue.set(state.selected_devices[data.index], 'is_in_cart', data.value)
  },

  setEmailData(state, email_data) {
    state.email_data = { ...state.email_data, ...email_data }
    state.reminder_email_data = { ...state.reminder_email_data, ...email_data }
  },

  setReminderEmailData(state, email_data) {
    state.reminder_email_data = { ...state.reminder_email_data, ...email_data }
  },

  setEmailTemplates: (state, templates) => {
    state.email_templates = templates
    state.current_email_template = templates[0]
  },

  setEmployees: (state, employeeData) => {
    state.employees = employeeData
    state.current_employee = state.user
  },

  setHasValidCustomerData(state, value) {
    state.has_valid_customer_data = value
  },

  setHasVoucher(state, value) {
    state.has_voucher = value
  },

  setInsuranceContract(state, contractData) {
    state.insurance_contract = contractData
  },

  setInsuranceToken: (state, token) => {
    state.insurance_token = token
  },

  setIsNewContract: (state, flag) => {
    state.is_new_contract = flag
  },

  setIsFirstDevice: (state, flag) => {
    Vue.set(state, 'is_first_device', flag)
  },

  setManufacturers: (state, { index, manufacturers }) => {
    Vue.set(state.selected_devices, index, { ...state.selected_devices[index], manufacturers })
  },

  setPremiums(state, index) {
    Vue.set(state.selected_devices, index, { ...state.selected_devices[index], premiums: state.product_quote[0].premiums })
  },

  setProductOffers(state, offers) {
    state.product_offers = offers
    Vue.set(state, 'product_offers', offers)
  },

  setProductQuote(state, quote) {
    Vue.set(state, 'product_quote', quote)
  },

  setPromotionOffers(state, promotion_offers) {
    state.promotions = promotion_offers
    Vue.set(state, 'promotions', promotion_offers)
  },

  setSelectedProduct: (state, selected_product) => {
    state.selected_product = selected_product
  },

  setStatus: (state, status) => {
    state.status = { ...state.status, ...status }
  },

  setUserData: (state, userData) => {
    Object.assign(state.user, { ...state.user, ...userData })
  },

  showMailCommunication: (state, flag) => {
    state.show_mail_communication = flag
  },

  unselectProduct: (state) => {
    state.selected_product = null
    state.product_offers = []
  },

  updateCustomer(state, customerData) {
    Vue.set(state, 'customer_data', { ...state.customer_data, ...customerData })
  },

  updateDevice(state, { index, device_data }) {
    Vue.set(state.selected_devices, index, { ...state.selected_devices[index], ...device_data })
  },

  updateNewContractDevice(state) {
    const device = {
      current_device_class_object: getDeviceClassObjectById(state.device_classes, state.current_device_class_id),
      current_manufacturer_object: null,
      is_new_device: true,
      is_in_cart: false,
      manufacturers: [],
      selected_components: []
    }

    Vue.set(state.selected_devices, 0, device)
  },

  updateSelectedComponent(state, payload) {
    const all = state.selected_devices[payload.cart_index].selected_components
    const updateIndex = all.findIndex(el => el.component_class_id === payload.component_class_id)
    all[updateIndex] = { ...payload }
  }
}

export default mutations
