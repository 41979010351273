<template>
  <v-card class="details" elevation="1">
    <v-card-text class="pb-2">
      <v-row align="center">
        <v-col v-if="!this.deviceDetails.is_external">
          <span class="detail_headline">Vertragsinformationen</span>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
              <tr v-for="(item, i) in contractInformation" :key="i">
                <th style="width: 40%">{{ item.name }}</th>
                <td :data-cy-contract-details="item.name.replace(':', '').toLowerCase()" >{{ item.value }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col>
          <span class="detail_headline">Geräteinformationen</span>

          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
              <tr v-for="(item, i) in deviceInformation" :key="i">
                <th style="width: 40%">{{ item.name }}</th>
                <td :data-cy-contract-details="item.name.replace(':', '').toLowerCase()" >{{ item.value }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-row v-if="componentInformation.length">
        <v-col>
          <span class="detail_headline">Komponenten</span>

          <v-simple-table
              v-for="(component, i) in componentInformation"
              :key="i"
              dense
              class="mb-3"
          >
            <template v-slot:default>
              <tbody>
              <tr v-for="(item, i) in component" :key="i">
                <th style="width: 40%">{{ item.name }}</th>
                <td :data-cy-contract-details="item.name.replace(':', '').toLowerCase()">{{ item.value }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import { mapGetters, mapState } from 'vuex'
import { has } from 'lodash'

export default {
  name: 'existing-device-details',
  data: () => ({}),
  computed: {
    ...mapGetters(['currentDeviceDetails', 'currentContractData']),
    ...mapState(['active_device_id']),
    contractDetails() {
      return this.currentContractData(this.active_device_id)
    },
    deviceDetails() {
      return this.currentDeviceDetails(this.active_device_id)
    },
    contractInformation() {
      const { contractDetails, deviceDetails } = this

      return [
        { name: 'Vertragsnummer:', value: contractDetails.contract_number },
        { name: 'Produkt:', value: contractDetails.product_name },
        { name: 'Beantragt:', value: this.formatDate(contractDetails.request_at) },
        { name: 'Unterschrieben am:', value: this.formatDate(contractDetails.sign_date) },
        { name: 'Vertragsstart:', value: this.formatDate(contractDetails.start_at) },
        { name: 'Zahlungsintervall:', value: contractDetails.payment_interval },
        { name: 'Zahlungsart:', value: contractDetails.fee_type },
        {
          name: 'Zahlung:',
          value: (contractDetails.fee_value !== null) ? this.formatPrice(contractDetails.fee_value) : '-'
        },
        { name: 'Komponenten:', value: (has(deviceDetails, 'components') ? 'ja' : 'nein') },
        {
          name: 'Sofortschutz:',
          value: ((has(deviceDetails, 'risks') && deviceDetails.risks.find(val => val.risk_name.toLowerCase() === 'sofortschutz')) ? 'ja' : 'nein')
        },
        {
          name: 'Diebstahlschutz:',
          value: ((has(deviceDetails, 'risks') && deviceDetails.risks.find(val => val.risk_name.toLowerCase() === 'diebstahlschutz')) ? 'ja' : 'nein')
        },
        { name: 'Vertragsstatus:', value: this.getContractStatus(contractDetails) },
        {
          name: this.terminationStatus,
          value: this.terminationDate
        },
        {
          name: 'Nächstmögl. Kündigungsdatum:',
          value: this.formatDate(contractDetails.next_possible_termination_date) || '-'
        }
      ]
    },
    terminationStatus() {
      return this.contractDetails.contract_status === 3 ? 'Gekündigt zum:' : 'Kündigung zu:'
    },
    terminationDate() {
      return (this.contractDetails.contract_status === 3 || this.contractDetails.is_terminated) ? this.formatDate(this.contractDetails.termination_date) : '-'
    },
    deviceInformation() {
      const { deviceDetails: device } = this

      let deviceInformation = [
        { name: 'Hersteller:', value: device.manufacturer_name },
        { name: 'Modell:', value: device.name },
        { name: 'Kaufdatum:', value: this.formatDate(device.purchased_at) },
        { name: 'Kaufpreis:', value: this.formatPrice(device.purchase_price) },
        { name: 'Seriennummer:', value: device.number || '-' },
        {
          name: 'Herstellungsdatum:',
          value: device.produced_at !== null ? this.formatDate(device.produced_at) : '-'
        },
        { name: 'Neugerät:', value: device.is_new ? 'ja' : 'nein' }
      ]

      if (device.isExternal) {
        if (has(device, 'attributes')) {
          deviceInformation.unshift({
            name: 'Alias',
            value: device.attributes[0].value
          })
        }
      }

      return deviceInformation
    },

    componentInformation() {
      const componentData = this.deviceDetails.components || []
      let components = []

      if (componentData.length) {
        componentData.forEach(component => {
          components.push([
            { name: 'Komponente:', value: component.name },
            { name: 'Hersteller:', value: component.manufacturer_name },
            { name: 'Modell:', value: component.type },
            { name: 'Kaufdatum:', value: this.formatDate(component.purchased_at) },
            { name: 'Kaufpreis:', value: this.formatPrice(component.purchase_price) }
          ])
        })
      }
      return components
    }
  },
  methods: {
    formatDate(date) {
      return this.moment(date).format('DD.MM.YYYY')
    },
    formatPrice(price) {
      if (price !== null && price.includes(',')) price = price.replace(',', '.')
      return new Intl.NumberFormat('de-DE',
          { style: 'currency', currency: 'EUR' }
      ).format(price)
    },
    getContractStatus(contractDetails) {
      const id = contractDetails.contract_status
      switch (id.toString()) {
        case '0':
          return `[${ id }] Mahnstufe / Beitragsrückstand`
        case '1':
          return contractDetails.is_terminated ? `[${ id }] aktiv / Kündigung vorgemerkt` : `[${ id }] aktiv`
        case '2':
          return `[${ id }] Vertrag befindet sich in Wartezeit`
        case '3':
          return `[${ id }] beendet oder storniert`
        default:
          return id
      }
    }
  }
}
</script>
