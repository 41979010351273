<template>
  <treeselect
      class="manufacturers-select"
      :class="{required: manufacturerRequired}"
      :options="device.manufacturers"
      :value="device.current_manufacturer_object"
      :label="manufacturerLabel"
      valueFormat="object"
      :normalizer="normalizer"
      placeholder="Hersteller *"
      no-results-text='Kein Hersteller gefunden. Bitte "Sonstige" wählen.'
      no-options-text='Keine Herstellerdaten vorhanden.'
      :disable-branch-nodes="true"
      @input="onManufacturerSelect"
      :disabled="device.is_in_cart"
  />
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import { has, isEmpty } from 'lodash'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'Manufacturer',
  components: { Treeselect },
  props: {
    device: {},
    cart_index: {},
    manufacturerRequired: {}
  },
  computed: {
    ...mapState(['current_device_class_id']),
    manufacturerLabel() {
      return isEmpty(this.device.manufacturers) ? '' : this.device.manufacturers.name
    }
  },
  async mounted() {
    if (!isEmpty(this.current_device_class_id) && !isEmpty(this.device.current_device_class_object)) {
      await this.fetchManufacturers(this.device.current_device_class_object.winsure_id)
    }

    if (isEmpty(this.device.current_manufacturer_object) && has(this.device, 'manufacturer')) {
      this.updateDevice({
        index: this.cart_index,
        device_data: {
          current_manufacturer_object: {
            id: parseInt(this.device.manufacturer),
            name: this.device.manufacturer_name
          }
        }
      })
    }
  },
  methods: {
    ...mapActions(['getManufacturers']),
    ...mapMutations(['updateDevice']),
    async fetchManufacturers(winsure_id) {
      await this.getManufacturers({
        winsure_id: winsure_id,
        index: this.cart_index
      })

      if (!isEmpty(this.device.manufacturer)) {
        this.updateDevice({
          index: this.cart_index,
          device_data: {
            current_manufacturer_object: {
              id: parseInt(this.device.manufacturer),
              name: this.device.manufacturers.find(obj => obj.id === parseInt(this.device.manufacturer)).name
            }
          }
        })
      }
    },
    normalizer(node) {
      return {
        label: node.name
      }
    },
    async onManufacturerSelect(node) {
      await this.updateDevice({
        index: this.cart_index,
        device_data: { current_manufacturer_object: node || null }
      })

      if (!isEmpty(node)) this.$emit('manufacturer-selected')
    }
  }
}
</script>
