import { has, isEmpty, omit } from 'lodash'
import moment from 'moment'

export function prepareEngagePrefillCheckoutData(state) {
  const currentDeviceClass = state.selected_devices[0].current_device_class_object

  const getDifferentIdIfDeviceIsBikeOrEbike = (device) => {
    if (device.type === 'bike') return 'b4f0b0ec-5c95-4bc0-a7f5-00544a994a4b'
    if (device.type === 'ebike') return 'ac8999a0-6e37-4488-8737-a566a068535b'
    return false
  }

  const getDifferentNameIfDeviceIsBikeOrEbike = (device) => {
    if (device.type === 'bike') return 'sonstiges Fahrrad'
    if (device.type === 'ebike') return 'E-sonstiges Fahrrad'
    return false
  }
  const device_class_id = getDifferentIdIfDeviceIsBikeOrEbike(currentDeviceClass) || currentDeviceClass.id
  const device_class_label = getDifferentNameIfDeviceIsBikeOrEbike(currentDeviceClass) || currentDeviceClass.name
  const product_id = state.selected_product.product_id

  const getPremiums = (device) => {
    const premiums = {
      premium: 0,
      selection_diebstahlschutz_0: 0,
      selection_sofortschutz_0: 0
    }

    const sofortschutzIsChecked = device.premiums.find(premium => premium.name === 'Sofortschutz')?.is_checked
    const diebstahlschutzIsChecked = device.premiums.find(premium => premium.name === 'Diebstahlschutz')?.is_checked

    if (sofortschutzIsChecked) {
      premiums.premium = 1
      premiums.selection_sofortschutz_0 = 1
    }

    if (diebstahlschutzIsChecked) {
      premiums.premium = 1
      premiums.selection_diebstahlschutz_0 = 1
    }

    return premiums
  }

  const premiums = getPremiums(state.selected_devices[0])

  const checkoutData = {
    contract_data: {
      cart: { ...state.cart },
      product: {
        device_class_id,
        device_class_label,
        product_id,
        ...premiums
      },
      customer_data: { ...state.customer_data },
      employee_id: parseInt(state.current_employee.id),
      broker_number: state.current_akp.number.toString()
    },
    email: { ...state.email_data },
    email_reminder: { ...state.reminder_email_data },
    settings: { ...state.settings }
  }

  if (state.has_voucher) {
    checkoutData.contract_data.voucher = state.current_akp.voucher
  }

  return checkoutData
}

export function prepareEngageDirectCheckoutData(state) {
  const checkoutData = {
    contract_data: {
      cart: { ...state.cart },
      customer_data: { ...state.customer_data },
      contract: { ...state.insurance_contract[0] },
      employee_id: parseInt(state.current_employee.id),
      broker_number: state.current_akp.number.toString()
    }, settings: { ...state.settings }
  }

  if (state.has_voucher) {
    checkoutData.contract_data.voucher = state.current_akp.voucher
  }

  return checkoutData
}

export function prepareInsuranceCheckoutData(state) {
  const customer = state.customer_data
  const birthday = customer.birthdate.includes(':') ? customer.birthdate.split(' ')[0] : customer.birthdate
  const checkoutData = {
    customer_salutation: customer.salutation,
    customer_title: customer.title,
    customer_company: null,
    customer_firstname: customer.first_name,
    customer_lastname: customer.last_name,
    customer_country: 'DE',
    customer_city: customer.city,
    customer_zip: customer.postalcode,
    customer_street: `${ customer.street } ${ customer.street_number }`,
    customer_email: customer.email_private !== ("" || null) ? customer.email_private : undefined,
    customer_phone: customer.phone_private || customer.mobile_phone_private,
    customer_birthday: birthday,
    debit_mandate_accepted: null,
    payment_method: customer.payment_method,
    terms_and_conditions_accepted: 1,
    broker_number: state.current_akp.number.toString()
  }

  if (has(customer, 'payment_iban') && !isEmpty(customer.payment_iban) && customer.payment_method === 'direct_debit') {
    checkoutData.payment_iban = customer.payment_iban
    checkoutData.debit_mandate_accepted = 1
  }

  if (has(customer, 'customer_number') && !isEmpty(customer.customer_number)) {
    checkoutData.customer_number = customer.customer_number
  }

  return checkoutData
}

export function getDeviceClassIdByWinsureId(deviceClasses, winsureId) {
  for (let deviceClass of deviceClasses) {
    for (let child of deviceClass.children) {
      if (winsureId.startsWith('27') && child.children) {
        for (let grandChild of child.children) {
          if (parseInt(grandChild.winsure_id, 10) === parseInt(winsureId, 10)) {
            return grandChild.id
          }
        }
      }

      if (parseInt(child.winsure_id, 10) === parseInt(winsureId, 10)) {
        return child.id
      }
    }
  }

  return null
}

export function getDeviceClassObjectById(device_classes, device_class_id) {
  for (let deviceClass of device_classes) {
    for (let child of deviceClass.children) {
      if (child.children) {
        for (let grandChild of child.children) {
          if (grandChild.id === device_class_id) {
            return grandChild
          }
        }
      }

      if (child.id === device_class_id) {
        return child
      }
    }
  }
  return null
}

export function getFilteredDeviceClasses(device_classes, allowed_device_classes) {
  let filtered = device_classes

  filtered.forEach(device_class => {
    device_class.children.forEach(children => {
      if (has(children, 'children')) {
        children.children.forEach(grand_children => {
          if (isEmpty(allowed_device_classes)) {
            grand_children.insurable = true
            grand_children.isDisabled = false
          } else if (grand_children.winsure_id !== null && allowed_device_classes.includes(grand_children.winsure_id)) {
            grand_children.insurable = true
            grand_children.isDisabled = false
          } else {
            grand_children.insurable = false
            grand_children.isDisabled = true
          }
        })
      }

      if (isEmpty(allowed_device_classes)) {
        children.insurable = true
        children.isDisabled = false
      } else if (children.winsure_id !== null && allowed_device_classes.includes(children.winsure_id)) {
        children.insurable = true
        children.isDisabled = false
      } else {
        children.insurable = false
        children.isDisabled = true
      }
    })
  })

  return filtered
}

export function getPaymentIntervals(selection_options) {
  let intervals = []
  let interval = {}

  selection_options.values.forEach(val => {
    switch (val) {
      case 1:
        interval = { name: 'monatlich', value: val }
        break
      case 3:
        interval = { name: 'quartalsmäßig', value: val }
        break
      case 6:
        interval = { name: 'halbjährlich', value: val }
        break
      case 12:
        interval = { name: 'jährlich', value: val }
        break
    }
    intervals.push(interval)
  })

  return intervals
}

export function getProductsForSelectedDevice(product_offers) {
  const products = []

  product_offers.forEach(offer => {
    products.push({
      product_id: offer.id, promotion_id: null, name: offer.name, value: offer.name.toLowerCase().replace(' ', '_')
    })

    if (!isEmpty(offer.applicable_promotions)) {
      products.push({
        product_id: offer.id,
        promotion_id: offer.applicable_promotions[0].id,
        name: offer.applicable_promotions[0].name,
        value: offer.applicable_promotions[0].name.toLowerCase().replaceAll(/\s/g, '_')
      })
    }
  })

  return products
}

export function getFooterColor(icon) {
  switch (icon) {
    case 'information':
      return 'white'
    case 'check':
      return 'green'
    default:
      return 'red'
  }
}

export function normalizeComponentData(componentData, deviceClasses) {
  const normalizedData = deviceClasses
  normalizedData.forEach(deviceClass => {
    deviceClass.children.forEach(children => {
      componentData.map(obj => {
        if (obj.id === children.id) {
          children.component_data = { ...obj }
        }
      })
    })
  })
  return normalizedData
}

export function normalizeCustomerData(data) {
  const filteredDevices = []
  const filteredContracts = []

  data.contracts.forEach(contract => {
    const today = moment().startOf('day')
    const terminationDate = moment(contract.termination_date)
    const is_terminated = terminationDate.isAfter(today)

    contract.is_terminated = is_terminated

    contract.devices.forEach((device, deviceIndex) => {
      filteredDevices.push({
        ...device,
        is_external: false,
        is_insurable: true,
        is_loading: false,
        product_name: contract.product_name,
        is_terminated: (is_terminated && device.status === 1)
      })
      contract.devices[deviceIndex] = device.id
    })
    filteredContracts.push({ ...contract })
  })

  if (!isEmpty(data.external_devices)) {
    data.external_devices.forEach(external_device => {
      filteredDevices.push({
        ...external_device,
        is_external: true,
        is_bike_manager: external_device.object_type === 'FAHRRAD',
        is_tech_manager: external_device.object_type === 'GERAET',
        is_insurable: true,
        is_loading: false,
        product_name: external_device.object_type === 'GERAET' ? 'TechManager' : 'BikeManager'
      })
    })
  }

  if (data?.birthdate !== null && data?.birthdate.includes(':')) {
    data.birthdate = data.birthdate.split(' ')[0]
  }

  return {
    contracts: filteredContracts,
    devices: filteredDevices,
    filtered_devices: filteredDevices,
    issues: data.issues,
    customer_data: {
      ...omit(data, ['contracts', 'external_devices', 'issues']),
      debit_mandate_accepted: null,
      payment_method: 'direct_debit',
      payment_iban: filteredContracts.find(contract => contract.iban !== null)?.iban || null
    }
  }
}

export function normalizeDeviceClasses(deviceClasses) {
  let normalizedData = deviceClasses

  normalizedData.forEach(deviceClass => {
    deviceClass.children.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

    deviceClass.children.forEach(children => {
      children.component_data = {}
      if (children.children.length === 0) {
        delete children.children
      } else {
        children.children.forEach(grandChildren => {
          if (grandChildren.type.toLowerCase() === 'bike' || grandChildren.type.toLowerCase() === 'ebike') {
            grandChildren.parent_name = children.name
            grandChildren.component_data = {}
          }
          delete grandChildren.children
        })
      }
    })
  })

  return normalizedData
}

export function normalizeAllowedDeviceClasses(deviceClasses) {
  let normalizedDeviceClasses = []
  deviceClasses.map(deviceClass => normalizedDeviceClasses.push(deviceClass.winsure_id))

  return normalizedDeviceClasses.sort()
}

export function normalizeProductQuote(quote) {
  let normalizedData = quote
  normalizedData[0].premiums.forEach(premium => {
    premium.is_checked = premium.required
  })
  return normalizedData
}

// Validators
export function isValidIBANNumber(input) {
  const CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    CL: 22,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20
  }

  let iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), digits

  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false
  }

  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55
  })
  return mod97(digits)
}

function mod97(string) {
  let checksum = string.slice(0, 2), fragment
  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7)
    checksum = parseInt(fragment, 10) % 97
  }
  return checksum === 1
}

export function isIMEI(s) {
  const pattern = /^[0-9]{15}$/
  if (!pattern.test(s)) return false
  let sum = 0
  let mul = 2
  let l = 14
  for (let i = 0; i < l; i++) {
    const digit = s.substring(l - i - 1, l - i)
    const tp = parseInt(digit, 10) * mul
    if (tp >= 10) sum += (tp % 10) + 1
    else sum += tp
    if (mul === 1) mul++
    else mul--
  }
  const chk = ((10 - (sum % 10)) % 10)
  return chk === parseInt(s.substring(14, 15), 10)

}

export function isValidEmail(email) {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

// Template Parsing
function get(path, obj, fb = `$\{${ path }}`) {
  return path.split('.').reduce((res, key) => res[key] || fb, obj)
}

export function parseTpl(template, map, fallback) {
  return template.replace(/{{(\w*)}}/g, (match) => {
    const path = match.substr(2, match.length - 4).trim()
    return get(path, map, fallback)
  })
}
