import axios from 'axios'
import store from '../store'

const isProd = () => process.env.VUE_APP_ENVIRONMENT === 'production'
const baseURL = isProd() ? process.env.VUE_APP_INSURANCE_PROD_URL : process.env.VUE_APP_INSURANCE_STAGE_URL
const clientId = isProd() ? process.env.VUE_APP_INSURANCE_PROD_CLIENT_ID : process.env.VUE_APP_INSURANCE_STAGE_CLIENT_ID
const clientSecret = isProd() ? process.env.VUE_APP_INSURANCE_PROD_CLIENT_SECRET : process.env.VUE_APP_INSURANCE_STAGE_CLIENT_SECRET

const api = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  // Auth
  async auth() {
    try {
      return await api.post('/oauth/token', {
        'grant_type': 'client_credentials',
        'client_id': clientId,
        'client_secret': clientSecret
      })
    } catch (e) {
      return this._reportError('insurance_authentication', e)
    }
  },
  _getAuthHeader() {
    return {
      headers: {
        Authorization: 'Bearer ' + store.state.insurance_token
      }
    }
  },

  // Device Classes
  async getComponentClasses() {
    try {
      return await api.get('/api/v1/component-classes', this._getAuthHeader())
    } catch (e) {
      return this._reportError('component_classes', e)
    }
  },

  // Products
  async getProductOffers(device_class_id) {
    try {
      return await api.get(`/api/v1/products/offers?device_class_id=${ device_class_id }&has_commercial=0`, this._getAuthHeader())
    } catch (e) {
      return this._reportError('getProductOffers', e)
    }
  },
  async getProductQuote(quoteData) {
    const {
      device_class_id,
      product_id,
      device_purchase_date,
      device_price,
      device_condition
    } = quoteData

    const isRefurbished = device_condition === '3' ? '1' : '0'

    const params = `product_id=${ product_id }&device_class_id=${ device_class_id }`
      + `&device_purchase_date=${ device_purchase_date }&device_price=${ device_price }`
      + `&is_refurbished=${ isRefurbished }&is_commercial=0`

    try {
      return await api.get(`api/v1/products/quote?${ params }`, this._getAuthHeader())
    } catch (e) {
      return this._reportError('product_quote', e)
    }
  },

  // Promotions
  async getPromotionOffers(device_class_id) {
    try {
      return await api.get(`/api/v1/promotions?device_class_id=${ device_class_id }&has_commercial=0`, this._getAuthHeader())
    } catch (e) {
      return this._reportError('getPromotionOffers', e)
    }
  },

  // Cart
  async addCartComponent(cart_device_id, component_class_id, component_data) {
    try {
      return await api.post(`/api/v1/cart/devices/components?cart_device_id=${ cart_device_id }&component_class_id=${ component_class_id }`,
        component_data, this._getAuthHeader())
    } catch (e) {
      return this._reportError('add_component', e)
    }
  },
  async addCartCoupon(cart_product_id, coupon_code, broker_number) {
    try {
      return await api.post(`/api/v1/cart/coupons?cart_product_id=${ cart_product_id }&coupon_code=${ coupon_code }&broker_number=${ broker_number }`,
        {}, this._getAuthHeader())
    } catch (e) {
      return this._reportError('add_coupon', e)
    }
  },
  async addCartPremium(cart_product_id, premium_product_id) {
    try {
      return await api.post(`/api/v1/cart/premiums?cart_product_id=${ cart_product_id }&premium_product_id=${ premium_product_id }`,
        {}, this._getAuthHeader())
    } catch (e) {
      return this._reportError('add_cart_premium', e)
    }
  },
  async addCartProduct(product_variant_id, device_class_id) {
    try {
      return await api.post(`/api/v1/cart/products?product_id=${ product_variant_id }&device_class_id=${ device_class_id }`,
        {}, this._getAuthHeader())
    } catch (e) {
      return this._reportError('add_cart_product', e)
    }
  },
  async addCartProductDiscount(discount_id, cart_product_id) {
    try {
      return await api.post(`/api/v1/cart/discounts?discount_id=${ discount_id }&cart_product_id=${ cart_product_id }`,
        {}, this._getAuthHeader())
    } catch (e) {
      return this._reportError('add_cart_product_discount', e)
    }
  },
  async addCartPromotion(promotion_id) {
    try {
      return await api.post(`/api/v1/cart/promotions?promotion_id=${ promotion_id }`, {}, this._getAuthHeader())
    } catch (e) {
      return this._reportError('add_cart_promotion', e)
    }
  },
  async checkoutCart(checkout_data) {
    try {
      return await api.post(`/api/v1/cart/checkout`, checkout_data, this._getAuthHeader())
    } catch (e) {
      return this._reportError('checkout', e)
    }
  },
  async deleteCartComponent(cart_component_id) {
    try {
      return await api.delete(`/api/v1/cart/devices/components?cart_component_id=${ cart_component_id }`, this._getAuthHeader())
    } catch (e) {
      return this._reportError('delete_component', e)
    }
  },
  async deleteCartCoupon(cart_product_id) {
    try {
      return await api.delete(`/api/v1/cart/coupons?cart_product_id=${ cart_product_id }`, this._getAuthHeader())
    } catch (e) {
      return this._reportError('delete_coupon', e)
    }
  },
  async deleteCartPremium(cart_product_premium_id) {
    try {
      return await api.delete(`/api/v1/cart/premiums?cart_product_premium_id=${ cart_product_premium_id }`, this._getAuthHeader())
    } catch (e) {
      return this._reportError('delete_cart_premium', e)
    }
  },
  async deleteCartProduct(cart_product_id) {
    try {
      return await api.delete(`/api/v1/cart/products?cart_product_id=${ cart_product_id }`, this._getAuthHeader())
    } catch (e) {
      return this._reportError('delete_cart_product', e)
    }
  },
  async deleteCartProductDiscount(cart_product_discount_id) {
    try {
      return await api.delete(`/api/v1/cart/discounts?cart_product_discount_id=${ cart_product_discount_id }`, this._getAuthHeader())
    } catch (e) {
      return this._reportError('delete_cart_product_discount', e)
    }
  },
  async getAllowedDeviceClasses(cart_promotion_id) {
    try {
      return await api.get(`/api/v1/cart/promotions/device-classes?cart_promotion_id=${ cart_promotion_id }`, this._getAuthHeader())
    } catch (e) {
      return this._reportError('get_allowed_device_classes', e)
    }
  },
  async pruneCart() {
    try {
      return await api.post('/api/v1/cart/prune', {}, this._getAuthHeader())
    } catch (e) {
      return this._reportError('cart_prune', e)
    }
  },
  async saveCart() {
    try {
      return await api.post('/api/v1/cart/save', {}, this._getAuthHeader())
    } catch (e) {
      return this._reportError('cart_save', e)
    }
  },
  async updateCartComponent(cart_component_id, component_data) {
    try {
      return await api.put(`/api/v1/cart/devices/components?cart_component_id=${ cart_component_id }`, component_data, this._getAuthHeader())
    } catch (e) {
      return this._reportError('update_device_data', e)
    }
  },
  async updateDeviceData(cart_device_id, device_data) {
    try {
      return await api.put(`/api/v1/cart/devices?cart_device_id=${ cart_device_id }`, device_data, this._getAuthHeader())
    } catch (e) {
      return this._reportError('update_device_data', e)
    }
  },
  async updatePaymentInterval(payment_interval) {
    try {
      return await api.put(`/api/v1/cart/?payment_interval=${ payment_interval }`, {}, this._getAuthHeader())
    } catch (e) {
      return this._reportError('update_payment_interval', e)
    }
  },
  async validateIban(iban) {
    try {
      const validate = await api.post(`/api/v1/cart/validate-iban?iban=${ iban }`, {}, this._getAuthHeader())
      return validate?.status === 200
    } catch (e) {
      return false
    }
  },

  // Misc
  _reportError(text, e) {
    if (e.response) {
      const errorMessages = []

      if (Object.keys(e.response.data.errors).length > 0) {
        if (e.response.data.errors.detail) {
          errorMessages.push(e.response.data.errors.detail)
        } else {
          for (let key in e.response.data.errors) {
            errorMessages.push(e.response.data.errors[key].join(', '))
          }
        }
      } else {
        errorMessages.push(e.response.data.message)
      }

      console.error(`${ e.response.status } - ${ text } failed`, e.response.data)
      return errorMessages.join(' | ')
    } else {
      console.error(`${ text } failed:`, e)
      return `Error executing "${ text }". (${ e.message })`
    }
  }
}
