<template>
    <v-form lazy-validation v-model="formIsValid" ref="deviceForm" class="fill-height">
        <v-card elevation="1" class="fill-height d-flex flex-column" :class=" {is_in_cart: device.is_in_cart }">
            <v-icon v-show="device.is_in_cart" color="green" class="mt-2 mr-3 justify-end">mdi-check</v-icon>
            <v-card-text class="flex-fill">
                <DeviceClass :device="device" :cart_index="cart_index"
                             :device-class-required="deviceClassRequired"
                             @device-class-selected="deviceClassRequired = false"
                />

                <Manufacturer :device="device" :cart_index="cart_index"
                              :manufacturer-required="manufacturerRequired"
                              @manufacturer-selected="manufacturerRequired =false"
                />

                <DeviceModel :device="device" :cart_index="cart_index" />
                <PurchaseDate :device="device" :cart_index="cart_index" />
                <PurchasePrice :device="device" :cart_index="cart_index" />

                <SerialNumber v-if="device_type !== 'smartphone'"
                              :device="device" :cart_index="cart_index" />

                <SmartphoneExtras v-if="device_type === 'smartphone'"
                                  :device="device"
                                  :cart_index="cart_index"
                />

                <BikeEbikeExtras :device="device" :cart_index="cart_index" />

                <Premiums v-if="hasPremiums"
                          :device="device"
                          :cart_index="cart_index"
                          :device-is-updating="deviceIsUpdating"
                          :price-is-updating="priceIsUpdating"
                />

                <DeviceCondition :device="device" :cart_index="cart_index" />
                <Components :device="device" :cart_index="cart_index" />
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text
                       @click="updateDeviceInCart"
                       :loading="(!device.is_in_cart && device_is_loading) || deviceIsUpdating || priceIsUpdating || premiumsAreUpdating">
                    {{ device.is_in_cart ? 'Bearbeiten' : 'In den Warenkorb' }}
                </v-btn>
                <v-spacer />
                <confirm ref="confirm" />
                <v-btn color="red" text @click="removeDevice">Löschen</v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { isEmpty } from 'lodash'
import Confirm from '@/components/confirm'
import DeviceClass from '@/components/device/DeviceClass'
import Manufacturer from '@/components/device/Manufacturer'
import DeviceModel from '@/components/device/DeviceModel'
import PurchaseDate from '@/components/device/PurchaseDate'
import PurchasePrice from '@/components/device/PurchasePrice'
import SerialNumber from '@/components/device/SerialNumber'
import SmartphoneExtras from '@/components/device/SmartphoneExtras'
import BikeEbikeExtras from '@/components/device/BikeEbikeExtras'
import Premiums from '@/components/device/Premiums'
import Components from '@/components/device/Components'
import DeviceCondition from '@/components/device/DeviceCondition'
import moment from 'moment/moment'

export default {
  name: 'device',
  components: {
    DeviceCondition,
    Components,
    Premiums,
    BikeEbikeExtras,
    SmartphoneExtras,
    SerialNumber,
    PurchasePrice,
    PurchaseDate,
    DeviceModel,
    Manufacturer,
    DeviceClass,
    Confirm
  },
  props: ['cart_index'],
  data: () => ({
    deviceClassRequired: false,
    deviceIsUpdating: false,
    formIsValid: false,
    manufacturerRequired: false,
    premiumsAreUpdating: false,
    priceIsUpdating: false
  }),
  computed: {
    ...mapState(['selected_devices', 'device_is_loading']),
    ...mapGetters(['isInCart']),
    dataIsValid() {
      return this.formIsValid && !this.deviceClassRequired && !this.manufacturerRequired
    },
    device() {
      return this.selected_devices[this.cart_index]
    },
    device_type() {
      return (!isEmpty(this.device.current_device_class_object)) ? this.device.current_device_class_object.type : ''
    },
    hasPremiums() {
      return !isEmpty(this.device.premiums)
    }
  },
  methods: {
    ...mapActions(['updateDeviceData', 'deleteCartProduct']),
    ...mapMutations(['updateDevice', 'unselectProduct', 'removeSelectedDevice']),
    async removeDevice() {
      if (isEmpty(this.device.current_device_class_object) || !this.isInCart(this.cart_index)) {
        this.removeSelectedDevice(this.cart_index)
        if (this.selected_devices.length < 1) this.unselectProduct()
        return
      }
      if (!this.device.current_device_class_object?.name) return

      if (await this.$refs.confirm.open('Löschen',
        `Wollen Sie wirklich "${ this.device.current_device_class_object.name }" löschen?`,
        { color: 'red' })) {
        await this.deleteCartProduct(this.cart_index)
      }
    },
    async updateDeviceInCart() {
      // Safari Workaround
      if (isEmpty(this.device.purchased_at)) {
        await this.updateDevice({
          index: this.cart_index,
          device_data: { purchased_at: moment().format('YYYY-MM-DD') }
        })
      }

      this.formIsValid = this.$refs.deviceForm.validate()

      if (this.device.is_in_cart) {
        await this.updateDevice({
          index: this.cart_index,
          device_data: { is_in_cart: false }
        })
      } else {
        this.deviceIsUpdating = true
        this.formIsValid = this.$refs.deviceForm.validate()
        this.deviceClassRequired = isEmpty(this.device.current_device_class_object)
        this.manufacturerRequired = isEmpty(this.device.current_manufacturer_object)

        if (this.dataIsValid) {
          await this.updateDeviceData(this.cart_index)
          this.$refs.deviceForm.resetValidation()
        }
        this.deviceIsUpdating = false
      }
    }
  }
}
</script>
