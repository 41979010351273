<template>
  <div>
    <v-text-field label="IMEI"
                  hide-details="auto"
                  :value="device.number"
                  :rules="[rules.required, rules.imei]"
                  @blur="updateDeviceState('number', $event.target.value)"
                  :disabled="device.is_in_cart"
    />

    <v-checkbox label="Frei von Schäden?"
                :value="deviceConditionConfirmed"
                :rules="[rules.required]"
                hide-details="auto"
                @change="updateDeviceConditionConfirmed"
                :disabled="device.is_in_cart"
    />
  </div>
</template>
<script>
import { isEmpty } from 'lodash'
import { isIMEI } from '@/store/helpers'
import { mapMutations } from 'vuex'

export default {
  name: 'SmartphoneExtras',
  props: {
    device: {},
    cart_index: {},
  },
  data: () => ({
    deviceConditionConfirmed: false,
    rules: {
      required: value => !!value || '* Pflichtfeld.',
      imei: value => {
        if (value !== null || value !== '') {
          return isIMEI(value) || 'Ungültige IMEI.'
        }
        return true
      }
    }
  }),
  computed: {
    device_type() {
      return (!isEmpty(this.device.current_device_class_object)) ? this.device.current_device_class_object.type : ''
    }
  },
  methods: {
    ...mapMutations(['updateDevice']),
    async updateDeviceState(key, value) {
      if (value !== '' && this.device[key] !== value) {
        await this.updateDevice({
          index: this.cart_index,
          device_data: { [key]: value }
        })
      }
    },
    updateDeviceConditionConfirmed(value) {
      this.deviceConditionConfirmed = !this.deviceConditionConfirmed
      this.updateDeviceState('device_condition_confirmed', value)
      this.updateDeviceState('device_os', 'other')
    }
  }
}
</script>
