<template>
  <v-app>
    <v-container fluid fill-height class="loginOverlay">
      <v-row align="center" justify="center">
        <v-col cols="4">
          <v-card elevation="3" shaped>
            <v-card-title class="primary white--text">
              <img
                src="../assets/h100w.png"
                alt="HYVE"
                width="100"
                class="d-inline"
              />
              <span class="pl-3 pt-1">ENGAGE</span>
            </v-card-title>
            <v-card-text class="pt-4">
              <div>
                <v-form data-cy-form="login-form" v-model="isValid" ref="form">
                  <v-text-field
                    label="E-Mail-Adresse"
                    v-model="email"
                    :rules="emailRules"
                    required
                    validate-on-blur
                    append-icon="mdi-at"
                    data-cy-input="login-email"
                  />
                  <v-text-field
                    label="Passwort"
                    type="password"
                    v-model="password"
                    :rules="passwordRules"
                    required
                    @keypress.enter.native="performLogin"
                    append-icon="mdi-lock"
                    data-cy-input="login-password"
                  />
                  <span class="red--text">{{ message }}</span>
                  <v-layout justify-space-between>
                    <v-spacer />
                    <v-btn
                      data-cy-cta="login-submit"
                      color="primary"
                      @click="performLogin"
                      :disabled="isDisabled"
                    >
                      Login
                    </v-btn>
                  </v-layout>
                </v-form>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "login",
  data() {
    return {
      email: "",
      emailRules: [
        (v) => !!v || "* Pflichtfeld",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Ungültige E-Mail",
      ],
      password: "",
      passwordRules: [(v) => !!v || "* Pflichtfeld"],
      msg: "",
      isValid: false,
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.user.engage_token,
      message: (state) => state.status.message,
      icon: (state) => state.status.icon,
    }),
    isDisabled() {
      return this.email === "" && this.password === "";
    },
  },
  methods: {
    ...mapActions(["login"]),
    async performLogin() {
      const credentials = {
        username: this.email,
        password: this.password,
      };

      await this.login(credentials);

      if (this.token !== null) {
        await this.$router.push("/");
      }
    },
  },
};
</script>
