<template>
  <div v-if="hasComponents" class="mt-5">
    <h4>Komponenten:</h4>
    <ul v-if="hasSelectedComponents" class="component-list">
      <li v-for="component in device.selected_components" :key="component.id">
        <v-row no-gutters align="start" justify="start">
          <v-col cols="9">
            {{ component.name }}
            <p class="component-subtitle">{{ getComponentSubtitle(component) }}</p>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-btn icon small>
              <v-icon color="red" :disabled="device.is_in_cart"
                      @click="removeComponent(component.cart_component_id)">
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </li>
    </ul>
    <v-btn color="primary" text class="text-left px-0 mb-5" @click="addComponent" :disabled="device.is_in_cart">
      <v-icon small class="pr-2">mdi-puzzle</v-icon>
      Komponenten bearbeiten
    </v-btn>
    <component-configurator v-model="showComponentsDialog" :cart_index="cart_index"
                            ref="componentConfigurator" />
  </div>
</template>
<script>
import ComponentConfigurator from '@/components/component-configurator'
import { has, isEmpty } from 'lodash'
import { mapActions } from 'vuex'

export default {
  name: 'Components',
  components: { ComponentConfigurator },
  props: {
    cart_index: {},
    device: {}
  },
  data: () => ({
    showComponentsDialog: false
  }),
  computed: {
    hasComponents() {
      return has(this.device.current_device_class_object, 'component_data')
          && !isEmpty(this.device.current_device_class_object.component_data)
    },
    hasSelectedComponents() {
      return !isEmpty(this.device.selected_components)
    }
  },
  methods: {
    ...mapActions(['deleteCartComponent']),
    addComponent() {
      this.$refs.componentConfigurator.populateSelectedComponents()
      this.showComponentsDialog = true
    },
    getComponentSubtitle(component) {
      return isEmpty(component.model_name) ? component.manufacturer : component.manufacturer + ' ' + component.model_name
    },
    async removeComponent(cart_component_id) {
      if (await this.$refs.confirm.open('Komponente löschen',
          `Wollen Sie wirklich die Komponente löschen?`,
          { color: 'red' })) {
        this.deviceIsUpdating = true
        await this.deleteCartComponent({ cart_component_id, cart_index: this.cart_index })
        this.deviceIsUpdating = false
      }
    }
  }
}
</script>
