<template>
  <v-row class="journal">
    <v-spacer/>
    <v-col cols="10" md="12" lg="10">
      <v-card class="px-8 py-5" elevation="3">
        <v-row>
          <v-col cols="8">
            <v-row justify="space-between" dense>
              <v-col cols="3">
                <h3>Verlauf</h3>
              </v-col>
              <v-col cols="9">
                <div class="text-right">
                  <v-autocomplete v-if="getDefaultEmployee.is_admin"
                      v-model="selectedEmployee"
                      :items="getEmployees"
                      @change="getJournal($event)"
                      dense
                      label="Verlauf für User"
                      :item-text="item => item.first_name +' '+ item.last_name"
                      item-value="id"
                  ></v-autocomplete>
                  <v-text-field dense label="Nachname" hide-details outlined @input="activeFilter.last_name = $event"/>
                  <v-text-field dense label="Partnernr" hide-details outlined
                                @input="activeFilter.customer_number = $event"/>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="text-right">
                <v-chip small
                        label
                        class="mr-2"
                        color="green"
                        :outlined="!hasFilter('neu')"
                        :text-color="hasFilter('neu') ? 'white' : ''"
                        @click="filter('neu')">
                  neu
                </v-chip>

                <v-chip small
                        label
                        class="mr-2"
                        color="red"
                        :outlined="!hasFilter('geschlossen')"
                        :text-color="hasFilter('geschlossen') ? 'white' : ''"
                        @click="filter('geschlossen')">
                  geschlossen
                </v-chip>

                <v-chip small
                        label
                        class="mr-2"
                        color="orange"
                        :outlined="!hasFilter('offen')"
                        :text-color="hasFilter('offen') ? 'white' : ''"
                        @click="filter('offen')">
                  offen
                </v-chip>

                <v-chip small
                        label
                        class="mr-2"
                        color="grey"
                        :outlined="!hasFilter('archiviert')"
                        :text-color="hasFilter('archiviert') ? 'white' : ''"
                        @click="filter('archiviert')">
                  archiviert
                </v-chip>
                </div>
              </v-col>
            </v-row>

            <v-progress-circular
                :size="36"
                :width="2"
                style="margin-top: 24px"
                color="#0064af"
                indeterminate
                v-if="!journal"
            />

            <v-row v-if="journal">
              <v-col>
                <v-data-table
                    :headers="headers"
                    :items="issues"
                    :custom-sort="customSort"
                    item-key="id"
                    :single-select="true"
                    class="elevation-2"
                    @click:row="clickProcess"
                    fixed-header
                    :footer-props="{
                      'items-per-page-text': $t('items_per_page'),
                      'items-per-page-all-text': $t('all'),
                      pageText: $t('items_page_text')
                    }"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="4" class="mb-8">
            <v-row v-if="journalSelected">
              <v-col cols="12">
                <v-row dense justify="space-between">
                  <v-col>
                    <h3>Status</h3>
                  </v-col>
                  <v-col>
                    <div class="text-right">
                      <v-text-field dense label="Status" hide-details outlined @input="activeFilter.status = $event"/>
                    </div>
                  </v-col>
                </v-row>

                <v-progress-circular
                    :size="36"
                    :width="2"
                    style="margin-top: 24px"
                    color="#0064af"
                    indeterminate
                    v-if="!selectedItem && journal"
                />

                <v-row v-if="selectedItem && statuses">
                  <v-col>
                    <v-data-table
                        :headers="headersStatus"
                        :items="statuses"
                        :custom-sort="customSort"
                        item-key="id"
                        :single-select="true"
                        class="elevation-2"
                        @click:row="clickStatus"
                        fixed-header
                        :footer-props="{
                          'items-per-page-text': $t('items_per_page'),
                          'items-per-page-all-text': $t('all'),
                           pageText: $t('items_page_text')
                        }"
                    ></v-data-table>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" v-if="selectedItem && selectedItem.type !== 'archived'">
                <v-dialog
                    v-model="dialog"
                    width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary"
                           v-bind="attrs"
                           v-on="on"
                    >
                      <v-icon left>mdi-archive</v-icon>
                      Vorgang archivieren
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h6">
                      Vorgang archivieren?
                    </v-card-title>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                          color="blue darken-1"
                          text
                          @click="dialog = false"
                      >
                        Nein
                      </v-btn>

                      <v-btn
                          color="blue darken-1"
                          text
                          @click="onArchive()"
                      >
                        Ja
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>

              <v-col cols="12" v-if="selectedStatus">
                <v-row dense>
                  <v-col>
                    <h3>Details</h3>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <v-expansion-panels accordion multiple>
                      <template v-for="(value, name) in selectedStatus">
                        <template v-if="value !== null && (Array.isArray(value) ? value.length : true)">
                          <template v-if="typeof value === 'object' && !Array.isArray(selectedStatus)">
                            <v-expansion-panel :key="name">
                              <v-expansion-panel-header class="intend-1">
                                <b>{{
                                    typeof name === 'number' ? $t('entry') + ' ' + (name + 1) : $t(name.toString())
                                  }}</b>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content class="intend-2">
                                <template v-for="(value2, name2) in value">
                                  <template v-if="value2 !== null && (Array.isArray(value2) ? value2.length : true)">
                                    <template v-if="typeof value2 === 'object'">
                                      <v-expansion-panel :key="name2">
                                        <v-expansion-panel-header class="intend-2"><b>{{
                                            typeof name2 === 'number' ? $t('entry') + ' ' + (name2 + 1) : $t(name2.toString())
                                          }}</b>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content class="intend-3">
                                          <template v-for="(value3, name3) in value2">
                                            <template
                                                v-if="value3 !== null && (Array.isArray(value3) ? value3.length : true)">
                                              <template v-if="typeof value3 === 'object'">
                                                <v-expansion-panel :key="name3">
                                                  <v-expansion-panel-header class="intend-3"><b>{{
                                                      typeof name3 === 'number' ? $t('entry') + ' ' + (name3 + 1) : $t(name3.toString())
                                                    }}</b>
                                                  </v-expansion-panel-header>
                                                  <v-expansion-panel-content class="intend-4">
                                                    <template v-for="(value4, name4) in value3">
                                                      <template
                                                          v-if="value4 !== null && (Array.isArray(value4) ? value4.length : true)">
                                                        <template v-if="typeof value4 === 'object'">
                                                          <v-expansion-panel :key="name4">
                                                            <v-expansion-panel-header class="intend-4">
                                                              <b>{{
                                                                  typeof name4 === 'number' ? $t('entry') + ' ' + (name4 + 1) : $t(name4.toString())
                                                                }}</b>
                                                            </v-expansion-panel-header>
                                                            <v-expansion-panel-content class="intend-5">
                                                              <template v-for="(value5, name5) in value4">
                                                                <template
                                                                    v-if="value5 !== null && (Array.isArray(value5) ? value5.length : true)">
                                                                  <template v-if="typeof value5 === 'object'">
                                                                    <v-expansion-panel :key="name5">
                                                                      <v-expansion-panel-header class="intend-5"><b>{{
                                                                          typeof name5 === 'number' ? $t('entry') + ' ' + (name5 + 1) : $t(name5.toString())
                                                                        }}</b>
                                                                      </v-expansion-panel-header>
                                                                      <v-expansion-panel-content class="intend-6">
                                                                        <template v-for="(value6, name6) in value5">
                                                                          <template
                                                                              v-if="value6 !== null && (Array.isArray(value6) ? value6.length : true)">
                                                                            <template v-if="typeof value6 === 'object'">
                                                                              <v-expansion-panel :key="name6">
                                                                                <v-expansion-panel-header
                                                                                    class="intend-6"><b>{{
                                                                                    typeof name6 === 'number' ? $t('entry') + ' ' + (name6 + 1) : $t(name6.toString())
                                                                                  }}</b>
                                                                                </v-expansion-panel-header>
                                                                                <v-expansion-panel-content
                                                                                    class="intend-7">
                                                                                  <template
                                                                                      v-for="(value7, name7) in value6">
                                                                                    <template
                                                                                        v-if="value7 !== null && (Array.isArray(value7) ? value7.length : true)">
                                                                                      <template
                                                                                          v-if="typeof value7 === 'object'">
                                                                                        <v-expansion-panel :key="name7">
                                                                                          <v-expansion-panel-header
                                                                                              class="intend-7"><b>{{
                                                                                              typeof name7 === 'number' ? $t('entry') + ' ' + (name7 + 1) : $t(name7.toString())
                                                                                            }}</b>
                                                                                          </v-expansion-panel-header>
                                                                                          <v-expansion-panel-content
                                                                                              class="intend-8">
                                                                                            <template
                                                                                                v-for="(value8, name8) in value7">
                                                                                              <template
                                                                                                  v-if="value8 !== null && (Array.isArray(value8) ? value8.length : true)">
                                                                                                <template
                                                                                                    v-if="typeof value8 === 'object'">
                                                                                                  <v-expansion-panel
                                                                                                      :key="name8">
                                                                                                    <v-expansion-panel-header
                                                                                                        class="intend-8">
                                                                                                      <b>{{
                                                                                                          typeof name8 === 'number' ? $t('entry') + ' ' + (name8 + 1) : $t(name8.toString())
                                                                                                        }}</b>
                                                                                                    </v-expansion-panel-header>
                                                                                                    <v-expansion-panel-content
                                                                                                        class="intend-9">
                                                                                                      <template
                                                                                                          v-for="(value9, name9) in value8">
                                                                                                        <template
                                                                                                            v-if="value9 !== null && (Array.isArray(value9) ? value9.length : true)">
                                                                                                          <template
                                                                                                              v-if="typeof value9 === 'object'">
                                                                                                            <v-expansion-panel
                                                                                                                :key="name9">
                                                                                                              <v-expansion-panel-header
                                                                                                                  class="intend-9">
                                                                                                                <b>{{
                                                                                                                    typeof name9 === 'number' ? $t('entry') + ' ' + (name9 + 1) : $t(name9.toString())
                                                                                                                  }}</b>
                                                                                                              </v-expansion-panel-header>
                                                                                                              <v-expansion-panel-content
                                                                                                                  class="intend-10">
                                                                                                                {{
                                                                                                                  value9 == 0 ? $t('no') : (value9 == 1 ? $t('yes') : $t(value9.toString()))
                                                                                                                }}
                                                                                                              </v-expansion-panel-content>
                                                                                                            </v-expansion-panel>
                                                                                                          </template>
                                                                                                          <template
                                                                                                              v-else>
                                                                                                            <div
                                                                                                                class="value">
                                                                                                              <b>{{
                                                                                                                  $t(name9) + ': '
                                                                                                                }}</b>{{
                                                                                                                value9 == 0 ? $t('no') : (value9 == 1 ? $t('yes') : $t(value9.toString()))
                                                                                                              }}
                                                                                                            </div>
                                                                                                          </template>
                                                                                                        </template>
                                                                                                      </template>
                                                                                                    </v-expansion-panel-content>
                                                                                                  </v-expansion-panel>
                                                                                                </template>
                                                                                                <template v-else>
                                                                                                  <div class="value"><b>{{
                                                                                                      $t(name8) + ': '
                                                                                                    }}</b>{{
                                                                                                      value8 == 0 ? $t('no') : (value8 == 1 ? $t('yes') : $t(value8.toString()))
                                                                                                    }}
                                                                                                  </div>
                                                                                                </template>
                                                                                              </template>
                                                                                            </template>
                                                                                          </v-expansion-panel-content>
                                                                                        </v-expansion-panel>
                                                                                      </template>
                                                                                      <template v-else>
                                                                                        <div class="value"><b>{{
                                                                                            $t(name7) + ': '
                                                                                          }}</b>{{
                                                                                            value7 == 0 ? $t('no') : (value7 == 1 ? $t('yes') : $t(value7.toString()))
                                                                                          }}
                                                                                        </div>
                                                                                      </template>
                                                                                    </template>
                                                                                  </template>
                                                                                </v-expansion-panel-content>
                                                                              </v-expansion-panel>
                                                                            </template>
                                                                            <template v-else>
                                                                              <div class="value"><b>{{
                                                                                  $t(name6) + ': '
                                                                                }}</b>{{
                                                                                  value6 == 0 ? $t('no') : (value6 == 1 ? $t('yes') : $t(value6.toString()))
                                                                                }}
                                                                              </div>
                                                                            </template>
                                                                          </template>
                                                                        </template>
                                                                      </v-expansion-panel-content>
                                                                    </v-expansion-panel>
                                                                  </template>
                                                                  <template v-else>
                                                                    <div class="value"><b>{{
                                                                        $t(name5) + ': '
                                                                      }}</b>{{
                                                                        value5 == 0 ? $t('no') : (value5 == 1 ? $t('yes') : $t(value5.toString()))
                                                                      }}
                                                                    </div>
                                                                  </template>
                                                                </template>
                                                              </template>
                                                            </v-expansion-panel-content>
                                                          </v-expansion-panel>
                                                        </template>
                                                        <template v-else>
                                                          <div class="value"><b>{{
                                                              $t(name4) + ': '
                                                            }}</b>{{
                                                              value4 == 0 ? $t('no') : (value4 == 1 ? $t('yes') : $t(value4.toString()))
                                                            }}
                                                          </div>
                                                        </template>
                                                      </template>
                                                    </template>
                                                  </v-expansion-panel-content>
                                                </v-expansion-panel>
                                              </template>
                                              <template v-else>
                                                <div class="value"><b>{{
                                                    $t(name3) + ': '
                                                  }}</b>{{
                                                    value3 == 0 ? $t('no') : (value3 == 1 ? $t('yes') : $t(value3.toString()))
                                                  }}
                                                </div>
                                              </template>
                                            </template>
                                          </template>
                                        </v-expansion-panel-content>
                                      </v-expansion-panel>
                                    </template>
                                    <template v-else>
                                      <div class="value"><b>{{
                                          $t(name2) + ': '
                                        }}</b>{{
                                          value2 == 0 ? $t('no') : (value2 == 1 ? $t('yes') : $t(value2.toString()))
                                        }}
                                      </div>
                                    </template>
                                  </template>
                                </template>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </template>
                          <template v-else>
                            <v-card class="intend-2">
                              <template v-if="typeof value === 'object'">
                                <template v-for="value2 in Object.keys(value)">
                                  <div class="value" v-if="value[value2] !== null"><b>{{
                                      $t(value2) + ': '
                                    }}</b>{{
                                      value[value2] == 0 ? $t('no') : (value[value2] == 1 ? $t('yes') : $t(value[value2]))
                                    }}
                                  </div>
                                </template>
                              </template>
                              <template v-else>
                                <div class="value"><b>{{
                                    $t(name) + ': '
                                  }}</b>{{ value == 0 ? $t('no') : (value == 1 ? $t('yes') : $t(value.toString())) }}
                                </div>
                              </template>
                            </v-card>
                          </template>
                        </template>
                      </template>
                    </v-expansion-panels>
                  </v-col>
                </v-row>

                <v-row v-if="selectedItem.employee_id !== selectedItem.user_employee.id">
                  <v-col>Abgeschlossen von {{ selectedItem.user_employee.first_name }}
                    {{ selectedItem.user_employee.first_name }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-spacer/>
  </v-row>
</template>

<script>
import engage from '../api/engage'
import {mapGetters} from "vuex";

export default {
  name: 'Journal',
  data() {
    return {
      headers: [
        {text: 'Datum', value: 'created_at', width: '120'},
        {text: 'Nachname', value: 'last_name'},
        {text: 'Vorname', value: 'first_name'},
        {text: 'Partnernr', value: 'customer_number'},
        {text: 'Verwendete AKP', value: 'akp_number'},
        {text: 'E-Mail Adresse', value: 'email'},
        {text: 'Status', value: 'type'}
      ],
      headersStatus: [
        {text: 'Datum', value: 'created_at'},
        {text: 'Status', value: 'type'}
      ],
      sortBy: 'created_at',
      journal: null,
      selectedItem: null,
      selectedStatus: null,
      selectedEmployee: null,
      journalSelected: false,
      activeFilter: {type: ['neu', 'offen', 'geschlossen'], last_name: null, customer_number: null, status: null},
      dialog: false
    }
  },

  mounted() {
    this.getJournal();
  },

  computed: {
    ...mapGetters(['getEmployees', 'getDefaultEmployee']),

    issues() {
      let issues = this.journal
      issues.forEach((res, i) => {
        issues[i].type = this.$t(res.type);
      })
      if (this.activeFilter.last_name) {
        issues = issues.filter(issue => issue.last_name.indexOf(this.activeFilter.last_name) > -1)
      }
      if (this.activeFilter.customer_number) {
        issues = issues.filter(issue => issue.customer_number.toString().indexOf(this.activeFilter.customer_number) > -1)
      }
      return issues.filter(status => this.activeFilter.type.includes(status.type))
    },

    statuses() {
      let statuses = this.selectedItem.statuses
      statuses.forEach((res, i) => {

        if (statuses[i].type === 'route_change') {
          statuses[i].payload.data = [{
            from: statuses[i].payload.from,
            to: statuses[i].payload.to
          }];
          delete statuses[i].payload.from;
          delete statuses[i].payload.to;
        }

        statuses[i].type = this.$t(res.type);
        if (statuses[i].payload) {

          if (statuses[i].payload.data) {
            statuses[i].payload = statuses[i].payload.data;
            delete statuses[i].payload.data;

            statuses[i].payload.forEach((res2, i2) => {
              delete statuses[i].payload[i2].transaction_number
            });
          }

          if (statuses[i].payload.settings) {
            delete statuses[i].payload.settings.direct_contract
            delete statuses[i].payload.settings.prefill_mode
          }
          if (statuses[i].payload.contract_data) {
            delete statuses[i].payload.contract_data.employee_id;

            if (statuses[i].payload.contract_data.products && statuses[i].payload.contract_data.products.length) {
              statuses[i].payload.contract_data.products.forEach((res2, i2) => {
                delete statuses[i].payload.contract_data.products[i2].device_class_id
                delete statuses[i].payload.contract_data.products[i2].product_id
                delete statuses[i].payload.contract_data.products[i2].product_variant_id

                if (statuses[i].payload.contract_data.products[i2].premiums && statuses[i].payload.contract_data.products[i2].premiums.length) {
                  statuses[i].payload.contract_data.products[i2].premiums.forEach((res3, i3) => {
                    delete statuses[i].payload.contract_data.products[i2].premiums[i3].premium_product_id
                  })

                  if (statuses[i].payload.contract_data.products[i2].premiums.length === 1) {
                    statuses[i].payload.contract_data.products[i2].premiums = statuses[i].payload.contract_data.products[i2].premiums[0];
                  }
                }
              })

              if (statuses[i].payload.contract_data.products.length === 1) {
                statuses[i].payload.contract_data.products = statuses[i].payload.contract_data.products[0];
              }
            }

            if (statuses[i].payload.contract_data.cart) {
              delete statuses[i].payload.contract_data.cart.id

              if (statuses[i].payload.contract_data.cart.products && statuses[i].payload.contract_data.cart.products.length) {
                statuses[i].payload.contract_data.cart.products.forEach((res2, i2) => {
                  delete statuses[i].payload.contract_data.cart.products[i2].id
                  delete statuses[i].payload.contract_data.cart.products[i2].devname
                  delete statuses[i].payload.contract_data.cart.products[i2].original_product

                  if (statuses[i].payload.contract_data.cart.products[i2].device) {
                    delete statuses[i].payload.contract_data.cart.products[i2].device.id
                    delete statuses[i].payload.contract_data.cart.products[i2].device.device_class_id

                    if (statuses[i].payload.contract_data.cart.products[i2].device.components && statuses[i].payload.contract_data.cart.products[i2].device.components.length) {
                      statuses[i].payload.contract_data.cart.products[i2].device.components.forEach((res3, i3) => {
                        delete statuses[i].payload.contract_data.cart.products[i2].device.components[i3].id
                        delete statuses[i].payload.contract_data.cart.products[i2].device.components[i3].component_class_id
                      });
                    }
                  }

                  if (statuses[i].payload.contract_data.cart.products[i2].premiums && statuses[i].payload.contract_data.cart.products[i2].premiums.length) {
                    statuses[i].payload.contract_data.cart.products[i2].premiums.forEach((res3, i3) => {
                      delete statuses[i].payload.contract_data.cart.products[i2].premiums[i3].id
                      delete statuses[i].payload.contract_data.cart.products[i2].premiums[i3].original_premium_id
                      delete statuses[i].payload.contract_data.cart.products[i2].premiums[i3].devname
                    })

                    if (statuses[i].payload.contract_data.cart.products[i2].premiums.length === 1) {
                      statuses[i].payload.contract_data.cart.products[i2].premiums = statuses[i].payload.contract_data.cart.products[i2].premiums[0];
                    }
                  }
                });

                if (statuses[i].payload.contract_data.cart.products.length === 1) {
                  statuses[i].payload.contract_data.cart.products = statuses[i].payload.contract_data.cart.products[0];
                }
              }

              if (statuses[i].payload.contract_data.cart.promotions && statuses[i].payload.contract_data.cart.promotions.length) {
                statuses[i].payload.contract_data.cart.promotions.forEach((res2, i2) => {
                  delete statuses[i].payload.contract_data.cart.promotions[i2].devname
                  delete statuses[i].payload.contract_data.cart.promotions[i2].id
                });
              }

            }
            if (statuses[i].payload.contract_data.customer_data) {
              delete statuses[i].payload.contract_data.customer_data.id;
              delete statuses[i].payload.contract_data.customer_data.user_id;
            }
            if (statuses[i].payload.contract_data.product) {
              delete statuses[i].payload.contract_data.product.device_class_id;
              delete statuses[i].payload.contract_data.product.product_id;
            }
          }
        }
      })
      if (this.activeFilter.status) {
        statuses = statuses.filter(issue => issue.type.indexOf(this.activeFilter.status) > -1)
      }
      return statuses
    }
  },

  methods: {
    async getJournal(id) {
      if (id) {
        this.selectedItem = null
        this.selectedStatus = null
        this.journalSelected = false;
      }
      const response = await engage.getJournalIssues(id)
      this.journal = response.data.data
      this.journal.forEach((res, index) => {
        const d = new Date(res.created_at)
        this.journal[index].created_at = this.getFormattedDate(d)
      })
    },

    async getJournalIssue(id) {
      const response = await engage.getJournalIssue(id)
      this.selectedItem = response.data.data
      this.selectedItem.statuses.forEach((res, index) => {
        const d = new Date(res.created_at)
        this.selectedItem.statuses[index].created_at = this.getFormattedDate(d)
      })
    },

    async onArchive() {
      this.dialog = false;
      await engage.archiveStatus(this.selectedItem.id);
      await this.getJournal();
      this.selectedItem = null;
      this.selectedStatus = null;
      this.journalSelected = false;
    },

    customSort: function (items, index, isDesc) {
      if (index[0] === 'created_at') {
        items.sort((a, b) => {
          if (!isDesc[0]) {
            return new Date(b.updated_at) - new Date(a.updated_at);
          } else {
            return new Date(a.updated_at) - new Date(b.updated_at);
          }
        });
      } else {
        items.sort((a, b) => {
          if (!isDesc[0]) {
            return b[index[0]] < a[index[0]] ? 1 : -1;
          } else {
            return a[index[0]] < b[index[0]] ? 1 : -1;
          }
        });
      }
      return items;
    },

    clickProcess(item, row) {
      row.select(true)
      this.journalSelected = true
      this.selectedItem = null
      this.selectedStatus = null
      this.getJournalIssue(item.id)
    },

    clickStatus(item, row) {
      row.select(true)
      this.selectedStatus = item.payload
    },

    getFormattedDate(d) {
      return d.toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }) + ' ' + (d.getHours() > 9 ? d.getHours() : '0' + d.getHours()) + ':' + (d.getMinutes() > 9 ? d.getMinutes() : '0' + d.getMinutes()) + ':' + (d.getSeconds() > 9 ? d.getSeconds() : '0' + d.getSeconds())
    },

    filter(by) {
      if (this.activeFilter.type.includes(by)) {
        this.activeFilter.type = this.activeFilter.type.filter(item => item !== by)
      } else {
        this.activeFilter.type.push(by)
      }
    },

    hasFilter(type) {
      return this.activeFilter.type.includes(type)
    }
  }
}
</script>

<style lang="scss">
.journal {
  .v-data-table {
    tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
      background: #bbdefb !important;
      cursor: pointer;
    }

    tr:nth-of-type(odd) {
      background-color: #F4FAFD;
    }

    tr.v-data-table__selected {
      background: #64b5f6 !important;
    }

    th {
      font-size: 11px !important;
    }

    td {
      font-size: 12px !important;
    }
  }

  .details.elevation-2 {
    padding: 10px;
  }

  .v-chip {
    border-style: solid;
    border-width: thin;
  }

  .v-input {
    max-width: 150px;
    display: inline-flex;
    margin-right: 10px !important;

    input {
      max-height: 30px;
    }
  }

  .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 30px;
    font-size: 12px;
  }

  .v-text-field--outlined.v-input--dense .v-label {
    top: 5px;
    font-size: 12px;
  }

  .v-expansion-panels--accordion > .v-expansion-panel {
    padding: 5px;
  }

  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }

  .v-expansion-panel-header {
    font-size: 14px !important;
    min-height: 28px !important;
    padding: 0 !important;

    &.intend-1 {
      padding-left: 10px !important;
    }

    &.intend-2 {
      padding-left: 20px !important;
    }

    &.intend-3 {
      padding-left: 30px !important;
    }

    &.intend-4 {
      padding-left: 40px !important;
    }

    &.intend-5 {
      padding-left: 50px !important;
    }

    &.intend-6 {
      padding-left: 60px !important;
    }

    &.intend-7 {
      padding-left: 70px !important;
    }

    &.intend-8 {
      padding-left: 80px !important;
    }

    &.intend-9 {
      padding-left: 90px !important;
    }

    &.intend-10 {
      padding-left: 100px !important;
    }

    &:hover {
      background: #bbdefb !important;
    }
  }

  .v-expansion-panel-content {
    font-size: 14px !important;

    &.intend-1 {
      .v-expansion-panel:before {
        content: none;
      }

      .value {
        padding-left: 10px !important;

        &:hover {
          background: #bbdefb !important;
          cursor: pointer;
        }
      }
    }

    &.intend-2 {
      .v-expansion-panel:before {
        content: none;
      }

      .value {
        padding-left: 20px !important;

        &:hover {
          background: #bbdefb !important;
          cursor: pointer;
        }
      }
    }

    &.intend-3 {
      .v-expansion-panel:before {
        content: none;
      }

      .value {
        padding-left: 30px !important;

        &:hover {
          background: #bbdefb !important;
          cursor: pointer;
        }
      }
    }

    &.intend-4 {
      .v-expansion-panel:before {
        content: none;
      }

      .value {
        padding-left: 40px !important;

        &:hover {
          background: #bbdefb !important;
          cursor: pointer;
        }
      }
    }

    &.intend-5 {
      .v-expansion-panel:before {
        content: none;
      }

      .value {
        padding-left: 50px !important;

        &:hover {
          background: #bbdefb !important;
          cursor: pointer;
        }
      }
    }

    &.intend-6 {
      .v-expansion-panel:before {
        content: none;
      }

      .value {
        padding-left: 60px !important;

        &:hover {
          background: #bbdefb !important;
          cursor: pointer;
        }
      }
    }

    &.intend-7 {
      .v-expansion-panel:before {
        content: none;
      }

      .value {
        padding-left: 70px !important;

        &:hover {
          background: #bbdefb !important;
          cursor: pointer;
        }
      }
    }

    &.intend-8 {
      .v-expansion-panel:before {
        content: none;
      }

      .value {
        padding-left: 80px !important;

        &:hover {
          background: #bbdefb !important;
          cursor: pointer;
        }
      }
    }

    &.intend-9 {
      .v-expansion-panel:before {
        content: none;
      }

      .value {
        padding-left: 90px !important;

        &:hover {
          background: #bbdefb !important;
          cursor: pointer;
        }
      }
    }
  }

  .value {
    line-height: 28px;
    min-height: 28px;
  }

  .v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel {
    margin-top: 0 !important;
  }

  .v-expansion-panel:not(:first-child)::after {
    border: 0 !important;
  }

  .v-card {
    flex: 1 0 100%;
    max-width: 100%;
    padding: 5px 15px;
    font-size: 14px !important;
  }
}

.v-dialog {
  .v-card__actions {
    display: block;
    text-align: center;
  }
}
</style>
